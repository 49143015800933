import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Add_DeviceToken,
  Mark_As_Read,
  ToggleNotification,
} from "../../../Mutation/Notification/mutation";
import client from "../../../apollo-client";
import { GET_Notifications } from "../../../Query/Notification/query";

export const notifications = createAsyncThunk(
  'user/notifications',
  async ({ notificationId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.mutate({
        mutation: ToggleNotification,
        variables: {
          notificationId: notificationId,
        },
      });
      return response.data.ToggleNotificationPreference;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getNotifications = createAsyncThunk(
  "user/getNotifications",
  async ({ first, page,device_id }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_Notifications,
        variables: { first, page,device_id },
      });
      return response.data.GetNotifications;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addDeviceToken = createAsyncThunk(
  "device/addDeviceToken",
  async ({ deviceType, deviceId, deviceToken }, { rejectWithValue }) => {
    try {
      const response = await client.mutate({
        mutation: Add_DeviceToken,
        variables: {
          device_type: deviceType,
          device_id: deviceId,
          device_token: deviceToken,
        },
      });
      return response.data.AddDeviceToken;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const markAsRead = createAsyncThunk(
  "notifications/markAsRead",
  async (device_id, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.mutate({
        mutation: Mark_As_Read,
        variables: { device_id },
      });
      return response.data.MarkAsRead;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
