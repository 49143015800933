import { gql } from "@apollo/client";

export const Raise_SupportTicket = gql`
  mutation RaiseSupportTicket(
    $product_name: String!
    $serial_no: String!
    $subject: String
    $message: String!
  ) {
    RaiseSupportTicket(
      input: {
        product_name: $product_name
        serial_no: $serial_no
        subject: $subject
        message: $message
      }
    ) {
      meta {
        status
        message
        code
      }
      support_ticket {
        id
        user {
          name
        }
        subject
        message
        status
        product_name
        created_at
        updated_at
      }
    }
  }
`;

export const Compose_CustomerSupportMail = gql`
  mutation ComposeCustomerSupportMail(
    $product_name: String!
    $serial_no: String!
    $subject: String
    $message: String!
  ) {
    ComposeCustomerSupportMail(
      input: {
        product_name: $product_name
        serial_no: $serial_no
        subject: $subject
        message: $message
      }
    ) {
      meta {
        status
        message
        code
      }
      support_ticket {
        id
        user {
          name
        }
        subject
        message
        status
        product_name
        created_at
        updated_at
      }
    }
  }
`;
export const Escalate_SupportTicket = gql`
  mutation EscalateSupportTicket($ticket_id: ID!, $reason: String!) {
    EscalateSupportTicket(ticket_id: $ticket_id, reason: $reason) {
      meta {
        status
        message
        code
      }
      support_ticket {
        id
        user {
          name
        }
        subject
        message
        status
        product_name
        created_at
        updated_at
      }
    }
  }
`;
