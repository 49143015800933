import { createSlice } from "@reduxjs/toolkit";
import { signIn } from "./actions";
import { notification } from "antd";
import { facebookLogin } from "../NetworkLocations/actions";

const initialState = {
  signInList: undefined,
  signInloading: false,
  signInerror: null,

  facebookLoginList: undefined,
  facebookLoginError: null,
  facebookLoginLoading: false,
};
const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.signInloading = true;
        state.error = null;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.signInloading = false;
        state.signInList = action.payload;
        notification.success({
          message: "Success",
          description: "Sign in Successfully",
          duration: 2,
        });
      })
      .addCase(signIn.rejected, (state, action) => {
        state.signInloading = false;
        state.signInerror = action.payload;
        notification.error({
          message: "Error",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })

      .addCase(facebookLogin.fulfilled, (state, action) => {
        state.facebookLoginLoading = false;
        state.facebookLoginList = action.payload;
        notification.success({
          message: "Success",
          description: "Login Successfully completed",
          duration: 2,
        });
      })
      .addCase(facebookLogin.rejected, (state, action) => {
        state.facebookLoginLoading = false;
        state.facebookLoginError = action.payload;
        notification.error({
          message: "Error",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })
      .addCase(facebookLogin.pending, (state) => {
        state.facebookLoginLoading = true;
        state.facebookLoginError = null;
      });
  },
});
export default loginSlice.reducer;