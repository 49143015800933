import { gql } from "@apollo/client";
export const Get_Documents = gql`
  query GetDocuments($first: Int!, $page: Int!,$search:String) 
    {
    GetDocuments(
			first:$first,
			page:$page
			search:$search
		){
			data{
				attachable_id
				attachable_type
				client_original
				created_at
				extension
				id
				mime_type
				name
				path
				size
				type
				updated_at
				data{
					title
				}
			}
       paginatorInfo {
				currentPage
				lastPage
				total
			}
    }
}`;