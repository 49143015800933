import { createSlice } from "@reduxjs/toolkit";
import { fetchFAQ } from "./actions";

const initialState = {
    faqloading: false,
    faqerror: null,
    faqlist: [],
}
const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFAQ.pending, (state) => {
                state.faqloading = true;
                state.faqerror = null;
            })
            .addCase(fetchFAQ.fulfilled, (state, action) => {
                state.faqloading = false;
                state.faqlist = action.payload;
            })
            .addCase(fetchFAQ.rejected, (state, action) => {
                state.faqloading = false;
                state.faqerror = action.payload;
            })
    },
});
export default faqSlice.reducer;