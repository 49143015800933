import { gql } from "@apollo/client";

export const Add_Review = gql`
  mutation AddReview(
    $product_id: Int!
    $ratings: Float!
    $comment: String
  ) {
    AddReview(
      input: {
        product_id: $product_id
        ratings: $ratings
        comment: $comment
      }
    ) {
      id
    }
  }
`;