import { gql } from "@apollo/client";

export const Create_ContactUs = gql`
  mutation CreateContactUs(
    $full_name: String!
    $email: String!
    $contact: String!
    $address: String!
    $postal_code: String!
    $country_id: ID!
    $state_id: ID!
    $city_id: ID
    $description: String!
  ) {
    CreateContactUs(
      input: {
        full_name: $full_name
        email: $email
        contact: $contact
        address: $address
        postal_code: $postal_code
        country_id: $country_id
        state_id: $state_id
        city_id: $city_id
        description: $description
      }
    ) {
      id
      full_name
      email
    }
  }
`;
