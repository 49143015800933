import { createAsyncThunk } from "@reduxjs/toolkit";
import { set_profile_image } from "../../../Mutation/SetProfile/mutation";
import client from "../../../apollo-client";

export const setProfileImage = createAsyncThunk(
    'profile/setProfileImage',
    async ({ avatar, name, phone_number }, { dispatch, rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: set_profile_image,
                variables: {
                    avatar: avatar,
                    name: name,
                    phone_number: phone_number,
                },
            });
            return response.data.SetProfile;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
