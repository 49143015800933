import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_PRODUCTS } from "../../../Query/HomePage/query";
import client from "../../../apollo-client";
import { GET_CATEGORIES } from "../../../Query/Categories/query";
import {
  GET_RandomProducts,
  GET_SimilarProducts,
  Get_ProductDetails,
  Get_ProductDetailsBySlug,
  Get_RegisteredProducts,
} from "../../../Query/Product/query";

export const fetchProducts = createAsyncThunk(
  'product/getProducts',
  async ({ first, page, str, categories ,type}, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_PRODUCTS,
        variables: { first, page, str, categories,type },
        fetchPolicy: 'no-cache',
      });
      const userData = response.data.user;
      const getProduct = {
        ...response.data.GetProducts,
        user: userData,
      };
      return getProduct;
      // return response.data.GetProducts;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCategories = createAsyncThunk(
  'product/getCategories',
  async ({ first, page }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_CATEGORIES,
        variables: { first, page },
      });
      return response.data.GetCategories;
    } catch (error) {
      throw rejectWithValue(error);
    }
  });

export const fetchProductDetails = createAsyncThunk(
  'product/fetchProductDetails',
  async (productId, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Get_ProductDetails,
        variables: { productId },
      });
      const userData = response.data.user;
      const productDetails = {
        ...response.data.GetProductDetails,
        user: userData,
      };
      return productDetails;
      //return response.data.GetProductDetails;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const fetchroductDetailsBySlug = createAsyncThunk(
  "product/fetchroductDetailsBySlug",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Get_ProductDetailsBySlug,
        variables: { slug },
      });
      const userData = response.data.user;
      const productDetails = {
        ...response.data.GetProductDetailsBySlug,
        user: userData,
      };
      return productDetails;
      //return response.data.GetProductDetails;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getRandomProducts = createAsyncThunk(
  'product/getRandomProducts',
  async ({ first, page }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_RandomProducts,
        variables: { first, page },
      });
      return response.data.GetRandomProducts;
    } catch (error) {
      throw rejectWithValue(error);
    }
  });

export const getSimilarProducts = createAsyncThunk(
  "product/getSimilarProducts",
  async ({ first, page, slug }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_SimilarProducts,
        variables: { first, page, slug },
      });
      return response.data.GetSimilarProducts;
    } catch (error) {
      throw rejectWithValue(error);
    }
  });

export const getRegisterProducts = createAsyncThunk(
  "product/getRegisterProducts",
  async ({ first, page ,str}, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Get_RegisteredProducts,
        variables: { first, page,str },
      });
      return response.data.GetRegisteredProducts;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
