import { createSlice } from "@reduxjs/toolkit";
import { deleteDocument, getDocuments } from "./actions";
import { notification } from "antd";

const initialState = {
    documentlist: null,
    documentloading: false,
    documenterror: null,

    documentdeletelist: null,
    documentdeleteloading: false,
    documentdeleteerror: null,
}
const documentSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDocuments.pending, (state) => {
                state.documentloading = true;
                state.documenterror = null;
            })
            .addCase(getDocuments.fulfilled, (state, action) => {
                state.documentloading = false;
                state.documentlist = action.payload;
            })
            .addCase(getDocuments.rejected, (state, action) => {
                state.documentloading = false;
                state.documenterror = action.payload;
            })
            .addCase(deleteDocument.pending, (state) => {
                state.documentdeleteloading = true;
                state.documentdeleteerror = null;
            })
            .addCase(deleteDocument.fulfilled, (state, action) => {
                state.documentloading = false;
                state.documentdeletelist = action.payload;
                notification.success({
                    message: 'Document Deleted!',
                    description: action?.payload?.meta?.message,
                    duration: 2
                });
            })
            .addCase(deleteDocument.rejected, (state, action) => {
                state.documentdeleteloading = false;
                state.documentdeleteerror = action.payload;
                notification.error({
                    message: "Error",
                    description: action?.payload?.graphQLErrors[0]?.message,
                    duration: 2
                });
            })
    },
});
export default documentSlice.reducer;