import { createSlice } from "@reduxjs/toolkit";
import { setProfileImage } from "./actions";

const initialState = {
    setImageloading: false,
    setImageerror: null,
    setImagelist: null,
}
const setImageSlice = createSlice({
    name: 'setImage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setProfileImage.pending, (state) => {
                state.setImageloading = true;
                state.setImageerror = null;
            })
            .addCase(setProfileImage.fulfilled, (state, action) => {
                state.setImageloading = false;
                state.setImagelist = action.payload;
            })
            .addCase(setProfileImage.rejected, (state, action) => {
                state.setImageloading = false;
                state.setImageerror = action.payload;
            })
    },
});
export default setImageSlice.reducer;