export default {
  token: {
    colorPrimary: "#E60C19",
    borderRadius: "2px",
    controlHeightLG:40,
    controlHeightSM:24,
    controlHeightXS:16,
    //fontFamily: "Century Gothic",
    // colorPrimaryHover
  },
  theme: "light",
};
