import { gql } from "@apollo/client";
export const GET_Notifications = gql`
  query GetNotifications($first: Int!, $page: Int!,$device_id:String!) {
    GetNotifications(first: $first, page: $page,device_id:$device_id) {
      data {
        id
        read_at
        created_at
        created_day
        created_time
        data {
          title
          message
          type
        }
      }
      paginatorInfo {
        currentPage
        total
      }
    }
  }
`;
