import { GET_FAQ } from "../../../Query/HomePage/query";
import { createAsyncThunk } from '@reduxjs/toolkit';
import client from "../../../apollo-client";
export const fetchFAQ = createAsyncThunk(
    'faq/getFAQ',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await client.query({
                query: GET_FAQ,
            });
            return response.data.GetFAQ;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);