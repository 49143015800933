import { createAsyncThunk } from "@reduxjs/toolkit";
import { SIGN_IN } from "../../../Mutation/Login/mutation";
import client from "../../../apollo-client";

export const signIn = createAsyncThunk(
    'auth/signIn',
    async ({ email, password }, { dispatch, rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: SIGN_IN,
                variables: {
                    email: email,
                    password: password,
                },
            });
            return response.data.SignIn;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

