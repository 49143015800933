import React from "react";
import { Layout } from "antd";
import { Row, Col } from "antd";
import Logo from "../../../../src/assests/Logo.svg";
import { Link } from "react-router-dom";
const { Footer } = Layout;
const isXsScreen = window.innerWidth <= 576;

const Footers = () => {
  return (
    <Footer className="bg-footer-primary privateWrapper">
      <Row justify={"space-between"} className="pt-6 pb-6">
        <Col xs={24} sm={24} md={24} lg={10} xl={10} className="text-bg-gray">
          <ul className="flex flex-col items-start list-none font-century-gothic pl-0">
            <div className="mb-6">
              <img src={Logo} alt="Logo" width={"152px"} height={"50px"} />
            </div>
            <li className="mb-5 font-normal text-base">
              Fischer is a trading style of Fischer Future Heat UK Limited and
              Fischer Heat UK Limited.Fischer Future Heat UK Limited T/A Fischer
              is authorised and regulated by the Financial Conduct Authority FRN
              732544.
            </li>
            <li className="text-base font-normal">Company No.: 10465510</li>
            <li className="text-base font-normal">VAT No.: GB 261 702 328</li>
          </ul>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={8}
          lg={5}
          xl={{ span: 3 }}
          className="text-bg-gray"
        >
          <ul className="flex flex-col items-start gap-5 list-none font-century-gothic pl-0">
            <li className="font-bold text-primary-red text-xl leading-10 font-century-gothic">
              Quick Links
            </li>
            <li className="text-base font-normal">
              <Link
                to="/our-products"
                className="text-bg-gray hover:text-bg-gray"
              >
                Our Products
              </Link>
            </li>
            <li className="text-base font-normal">
              <Link
                to="/loyalty-reward"
                className="text-bg-gray hover:text-bg-gray"
              >
                Loyalty Rewards
              </Link>
            </li>
            <li className="text-base font-normal">
              <Link
                to="/terms-and-conditions"
                className="text-bg-gray hover:text-bg-gray"
              >
                Terms & Conditions
              </Link>
            </li>
            <li className="text-base font-normal">
              <Link
                to="/privacy-policy"
                className="text-bg-gray hover:text-bg-gray"
              >
                Privacy policy
              </Link>
            </li>
          </ul>
        </Col>
        <Col xs={24} sm={24} md={8} lg={4} xl={3} className="text-bg-gray">
          <ul className="flex flex-col items-start gap-5 list-none font-century-gothic pl-0">
            <li className="font-bold text-primary-red text-xl leading-10 font-century-gothic">
              Get to Know Us
            </li>
            <li className="text-base font-normal">
              <Link to="/about-us" className="text-bg-gray hover:text-bg-gray">
                About Us
              </Link>
            </li>
            <li className="text-base font-normal">
              <Link to="/contact-us" className="text-bg-gray hover:text-bg-gray">
                Contact Us
              </Link>
            </li>
          </ul>
        </Col>
        <Col xs={24} sm={24} md={8} lg={5} xl={3} className="text-bg-gray">
          <div className="text-bg-gray">
            <ul className="flex flex-col items-start gap-5 list-none font-century-gothic pl-0">
              <li className="font-bold text-primary-red text-xl leading-10 font-century-gothic">
                Contact
              </li>
              <li className="text-base font-normal">
                <Link
                  to="tel:+08001032723"
                  className="text-bg-gray hover:text-bg-gray flex items-center"
                >
                  <i className="icon-call" style={{ fontSize: "22px" }}></i>
                  <span className=" text-bg-gray">020 8126 8899</span>
                </Link>
              </li>
              <li className="text-base font-normal">
                <Link
                  to="mailto:info@fischertrade.co.uk"
                  className="text-bg-gray hover:text-bg-gray flex items-center"
                >
                  <i className="icon-website" style={{ fontSize: "22px" }}></i>
                  <span className=" text-bg-gray">info@fischertrade.co.uk</span>
                </Link>
              </li>
              <li className="flex">
                <Link
                  to="https://www.facebook.com/fischerfutureheat"
                  className="text-bg-gray hover:text-bg-gray flex items-center"
                >
                  <i className="icon-facebook px-[1px] py-[4px] mr-2 rounded-md bg-tag-primary" style={{ fontSize: "22px", color:'white' }}></i>
                </Link>
                <Link
                  to="https://twitter.com/FischerWarmHome"
                  className="text-bg-gray hover:text-bg-gray flex items-center"
                >
                  <i className="icon-twitter px-[1px] py-[4px] mr-2 rounded-md bg-tag-primary" style={{ fontSize: "22px", color:'white' }}></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/fischer-future-heat-group-limited"
                  className="text-bg-gray hover:text-bg-gray flex items-center"
                >
                  <i className="icon-linkedin px-[1px] py-[4px] mr-2 rounded-md bg-tag-primary" style={{ fontSize: "22px", color:'white' }}></i>
                </Link>
                <Link
                  to="https://www.instagram.com/fischerfutureheat"
                  className="text-bg-gray hover:text-bg-gray flex items-center"
                >
                  <i className="icon-instagram px-[1px] py-[4px] mr-2 rounded-md bg-tag-primary" style={{ fontSize: "22px", color:'white' }}></i>
                </Link>

              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <div style={{ margin: "16px 16px", borderBottom: "1px solid #DCDCDC" }} />
      <div className="text-center text-bg-gray mt-8">
        &copy; Fischer Future Heat UK Limited. All Rights Reserved.
      </div>
    </Footer>
  );
};
export default Footers;
