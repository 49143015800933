import { configureStore } from "@reduxjs/toolkit";
import networkLocationReducer from "./NetworkLocations/slice";
import notificationsReducer from "./Notification/slice";
import faqReducer from "./FAQ/slice";
import testimonialReducer from "./Testimonial/slice";
import bannerReducer from "./Banner/slice";
import loginReducer from "./Login/slice";
import setImageReducer from "./SetProfileImage/slice";
import forgetpasswordReducer from "./ForgetPassword/slice";
import productReducer from "./Product/slice";
import documentsReducer from "./Documents/slice";
import wishListReducer from "./WishList/slice";
import cartReducer from "./Cart/slice";
import productRegisterReducer from "./ProductRegister/slice";
import orderReducer from "./Order/slice";
import rewardsReducer from "./Rewards/slice";
import chatReducer from "./Chat/slice";
import superAdminReducer from "./SuperAdmin/slice";
import contactUsReducer from "./ContactUs/slice";
import customersupportReducer from "./CustomerSupport/slice";
const store = configureStore({
  reducer: {
    networkLocations: networkLocationReducer,
    notifications: notificationsReducer,
    faq: faqReducer,
    testimonial: testimonialReducer,
    banner: bannerReducer,
    login: loginReducer,
    setImage: setImageReducer,
    forgetpassword: forgetpasswordReducer,
    product: productReducer,
    documents: documentsReducer,
    wishList: wishListReducer,
    cart: cartReducer,
    productRegister: productRegisterReducer,
    order:orderReducer,
    rewards:rewardsReducer,
    chat: chatReducer,
    superAdmin:superAdminReducer,
    contactUs:contactUsReducer,
    customersupport:customersupportReducer
  },
});

export default store;
