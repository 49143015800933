import { createSlice } from "@reduxjs/toolkit";
import {
  getAboutUs,
  getRewardPoints,
  getRewardPointsHistories,
} from "./actions";
import { notification } from "antd";

const initialState = {
  rewardslist: undefined,
  rewardsloading: false,
  rewardserror: undefined,

  rewardHistorylist: undefined,
  rewardHistoryloading: false,
  rewardHistoryerror: undefined,

  aboutuslist: undefined,
  aboutusloading: false,
  aboutuserror: undefined,
};
const rewardPointSlice = createSlice({
  name: "rewards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRewardPoints.pending, (state) => {
        state.rewardsloading = true;
        state.rewardserror = null;
      })
      .addCase(getRewardPoints.fulfilled, (state, action) => {
        state.rewardsloading = false;
        state.rewardslist = action.payload;
        if (
          action?.payload?.meta?.status === true &&
          action?.payload?.meta?.code === 200
        ) {
          notification.success({
            description: action?.payload?.meta?.message,
            duration: 2,
          });
        }
      })
      .addCase(getRewardPoints.rejected, (state, action) => {
        state.rewardsloading = false;
        state.rewardserror = action;
      })

      .addCase(getRewardPointsHistories.rejected, (state, action) => {
        state.rewardHistoryloading = false;
        state.rewardHistoryerror = action;
      })
      .addCase(getRewardPointsHistories.pending, (state) => {
        state.rewardHistoryloading = true;
        state.rewardHistoryerror = null;
      })
      .addCase(getRewardPointsHistories.fulfilled, (state, action) => {
        state.rewardHistoryloading = false;
        state.rewardHistorylist = action.payload;
      })

      .addCase(getAboutUs.rejected, (state, action) => {
        state.aboutusloading = false;
        state.aboutuserror = action;
      })
      .addCase(getAboutUs.pending, (state) => {
        state.aboutusloading = true;
        state.aboutuserror = null;
      })
      .addCase(getAboutUs.fulfilled, (state, action) => {
        state.aboutusloading = false;
        state.aboutuslist = action.payload;
      });
  },
});
export default rewardPointSlice.reducer;
