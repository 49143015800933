import { createSlice } from "@reduxjs/toolkit";
import { fetchBanners } from "./actions";

const initialState = {
    bannerlist: [],
    bannerloading: false,
    bannererror: null,
}
const bannerSlice = createSlice({
    name: 'banner',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBanners.pending, (state) => {
                state.bannerloading = true;
                state.bannererror = null;
            })
            .addCase(fetchBanners.fulfilled, (state, action) => {
                state.bannerloading = false;
                state.bannerlist = action.payload;
            })
            .addCase(fetchBanners.rejected, (state, action) => {
                state.bannerloading = false;
                state.bannererror = action.payload;
            })
    },
});
export default bannerSlice.reducer;