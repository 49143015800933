import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../../apollo-client";
import { Get_Messages } from "../../../Query/Chat/query";
import { SEND_MESSAGE } from "../../../Mutation/Chat/mutation";

export const getMessages = createAsyncThunk(
  "user/getMessages",
  async ({ first, page }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Get_Messages,
        variables: { first, page },
      });
      //return response.data.GetMessages;
      const userData = response.data.user;
      const getMessage = {
        ...response.data.GetMessages,
        user: userData,
      };
      return getMessage;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const sendMessages = createAsyncThunk(
  "user/sendMessages",
  async ({ message, chat_support_req }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: SEND_MESSAGE,
        variables: { message, chat_support_req },
      });
      return response.data.SendMessage;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
