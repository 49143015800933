import { createAsyncThunk } from "@reduxjs/toolkit";
import { REGISTER_PRODUCT } from "../../../Mutation/Product/mutation";
import client from "../../../apollo-client";

export const registerProduct = createAsyncThunk(
    'product/registerProduct',
    async ({ categoryId, serialNo, productName, dateOfPurchase }, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: REGISTER_PRODUCT,
                variables: {
                    categoryId: categoryId,
                    serialNo: serialNo,
                    productName: productName,
                    dateOfPurchase: dateOfPurchase,
                },
            });
            return response.data.RegisterProduct;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);