import {
  Avatar,
  Badge,
  Button,
  FloatButton,
  Form,
  Input,
  Popover,
  Select,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import person from "../../../assests/userdefault.jpg";
import "./MessageOverlay.scss";
import typing from "../../../assests/typing.gif";
import TextArea from "antd/es/input/TextArea";
import { RightOutlined } from "@ant-design/icons";
import EmojiPicker from "emoji-picker-react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMessages,
  sendMessages,
} from "../../../services/Store/Chat/actions";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { newchatlist } from "../../../services/Store/Chat/slice";
import { superAdmin } from "../../../services/Store/SuperAdmin/actions";
import Cookies from "js-cookie";
import { fetchUserProfile } from "../../../services/Store/NetworkLocations/actions";
import Loaders from "../../Loader";
const MessageOverlay = () => {
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const [isOpenEmoji, setIsOpenEmoji] = useState(false);
  const chatContainerRef = useRef(null);
  const { chatlist, chatloading } = useSelector((state) => state.chat);
  const [isListenerAdded, setIsListenerAdded] = useState(false);
  const [chatData, setChatData] = useState([]);
  const { superAdminlist } = useSelector((state) => state.superAdmin);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [loading, setLoading] = useState(false);
  const { getProfileList } = useSelector((state) => state.networkLocations);
  const [supportClosedMessage, setSupportClosedMessage] = useState();
  const [unreadChatCount, setUnreadChatCount] = useState();
  const CHAT_BRODCASTER = process.env.REACT_APP_CHAT_BRODCASTER;
  const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY;
  const CHAT_WHHOST = process.env.REACT_APP_CHAT_WHHOST;
  const CHAT_WSPORT = process.env.REACT_APP_CHAT_WSPORT;
  const CHAT_WSSPORT = process.env.REACT_APP_CHAT_WSSPORT;
  const CHAT_FORCETLS = process.env.REACT_APP_CHAT_FORCETLS;
  const CHAT_ENABLEDTRANSPORTS = ["ws", "wss"];
  const CHAT_AUTHENDPOINTS = process.env.REACT_APP_CHAT_AUTHENDPOINTS;

  const accessToken = Cookies.get("access_token");
  const user_Id = Cookies.get("user_Id");
  useEffect(() => {
    fetchData();
  }, [dispatch, isOpen, currentPage]);
  useEffect(() => {
    dispatch(fetchUserProfile());
  }, []);

  const fetchData = () => {
    dispatch(getMessages({ first: 10, page: currentPage }))
      .then((response) => {
        const userId = response.payload?.user?.id;
        let transformedMessages = response.payload?.data?.map((message) => {
          const isSender = message?.from?.id === userId;
          const isReceiver = message?.to?.id !== userId;
          return {
            id: message?.id,
            message: message?.message,
            UserId: message?.from?.name,
            isSender: isSender,
            isReceiver: isReceiver,
            date: message?.created_at,
          };
        });
        if (currentPage > 1) {
          // If currentPage is greater than 1, concat the new messages
          if (transformedMessages && transformedMessages.length > 0) {
            let reversedMessages = transformedMessages.reverse(); // Reverse transformedMessages
            let newMessages = [...reversedMessages, ...chatData];
            setChatData(newMessages);
          }
        } else {
          // If currentPage is 1, replace existing chatData with new messages
          if (transformedMessages && transformedMessages?.length > 0) {
            let latestMessages = transformedMessages.reverse();
            setChatData(latestMessages);
          }
        }
        setIsListenerAdded(false);
      })
      .catch((error) => {
        // Handle error if necessary
        console.error("Error fetching initial messages:", error);
      });
  };
  useEffect(() => {
    if (isOpen) {
      dispatch(superAdmin());
    }
  }, [isOpen]);

  // Date & time format function
  const parseTimestampToTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const hour = hours % 12;
    const minute = minutes < 10 ? "0" + minutes : minutes;
    return hour + ":" + minute + " " + ampm;
  };

  //set unread message on state
  useEffect(() => {
    setUnreadChatCount(getProfileList?.unread_messages || 0);
  }, [getProfileList]);

  useEffect(() => {
    window.Pusher = Pusher;
    window.Echo = new Echo({
      broadcaster: CHAT_BRODCASTER,
      key: REACT_APP_API_KEY,
      wsHost: CHAT_WHHOST,
      wsPort: CHAT_WSPORT,
      wssPort: CHAT_WSSPORT,
      forceTLS: CHAT_FORCETLS,
      enabledTransports: CHAT_ENABLEDTRANSPORTS,
      authEndpoint: CHAT_AUTHENDPOINTS,
      auth: {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : "",
        },
      },
    });

    const channel = `messages.${user_Id}`;
    // Check if the listener has not been added yet
    if (!isListenerAdded) {
      window.Echo.private(channel)
        .listen(".NewMessage", (e) => {
          if (!isOpen) {
            setUnreadChatCount((prevCount) => prevCount + 1);
          }
          dispatch(newchatlist(e.message));
          setCurrentPage(1);
          scrollToBottom();
          setIsListenerAdded(true);
        })
        .listen(".CloseSupport", (e) => {
          const supportAlertMessage = e.message;
          setChatData([]);
          setSupportClosedMessage(supportAlertMessage);
          setIsListenerAdded(true);
        });
    }
    setIsListenerAdded(false);
    // Cleanup function to remove the event listener if needed
    return () => {
      // Reset the flag when the component unmounts
      setSupportClosedMessage();
      setIsListenerAdded(false);
    };
  }, []);
  const onFinish = (values) => {
    const messageString = `*Name:* \n ${values.name} \n\n *Email Address:* \n ${values.email} \n\n *What brings you to Fischer today? (e.g., inquiry, feedback, issue resolution)* \n ${values.inquiry} \n\n *Briefly describe how we can assist you:* \n ${values.assistanceDetails}`;
    try {
      setLoading(true);
      dispatch(
        sendMessages({
          message: messageString,
          chat_support_req: true,
        })
      );
          // dispatch(getMessages({ first: 10, page: 1 }));
      //fetchData();
      setTimeout(() => {
        fetchData();
        form.resetFields();
      }, 1000);
      setIsListenerAdded(false);
      scrollToBottom();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const userId = chatlist?.user?.id;
    if (chatlist?.data?.length > 0) {
      const firstMessage = chatlist?.data[0];
      const isSender = firstMessage?.from?.id === userId;
      const isReceiver = firstMessage?.to?.id !== userId;
      const transformedMessages = {
        id: firstMessage?.id,
        message: firstMessage?.message,
        UserId: firstMessage?.from?.name,
        isSender: isSender,
        isReceiver: isReceiver,
        date: firstMessage?.created_at,
      };
      scrollToBottom();
      setChatData((prevChatData) => [...prevChatData, transformedMessages]);
    }
    //setChatData(transformedMessages);
  }, [!isOpen, chatlist]);

  const onClickOpenEmoji = () => {
    setIsOpenEmoji(!isOpenEmoji);
  };

  const onEmojiClick = (e) => {
    setValue((pre) => pre + e?.emoji);
  };
  const parseMessage = (text) => {
    // Remove all HTML tags from the message using a regular expression
    const plainText = text.replace(/<[^>]*>/g, "");

    // Replace special characters and escape sequences
    const parsedMessage = plainText
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/\n/g, "<br>")
      .replace(/\*(.*?)\*/g, "<strong>$1</strong>");

    return parsedMessage;
  };
  const fetchMoreData = () => {
    setCurrentPage(currentPage + 1);
    setScrollPosition(chatContainerRef.current.scrollHeight);
  };
  useEffect(() => {
    // Restore scroll position after loading more messages
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight - scrollPosition;
    }
  }, [chatData]);
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const chatContainer = chatContainerRef.current;
      const lastMessage = chatContainer.lastElementChild;
      if (lastMessage) {
        lastMessage.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }
  };

  const parseToSimpleMessage = (text) => {
    if (!text) {
      return "";
    }
    // Remove all HTML tags from the message using a regular expression
    const plainText = text.replace(/<[^>]*>/g, "");
    // Replace special characters and escape sequences
    const parsedMessage = plainText
      .replace(/&/g, "")
      .replace(/</g, "")
      .replace(/>/g, "")
      .replace(/\n/g, "")
      .replace(/\*(.*?)\*/g, "$1");
    return parsedMessage;
  };

  const sendMessage = () => {
    if (!value.trim()) {
      return;
    }

    const simpleMessage = parseToSimpleMessage(value);
    dispatch(sendMessages({ message: simpleMessage, chat_support_req: null }))
      .then((response) => {
        const { id, message, created_at, from, to } = response.payload;
        const newData = {
          id,
          message,
          UserId: from.name,
          isSender: true,
          isReceiver: false,
          date: created_at,
        };
        setScrollPosition(0);
        // Update chatData state with the new message
        setChatData((prevChatData) => [...prevChatData, newData]);
        // Scroll to bottom after adding the new message
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      })
      .catch((error) => {
        // Handle error if necessary
        console.error("Error sending message:", error);
      });
    setValue("");
    setIsOpenEmoji(false);
  };

  const handleScroll = (event) => {
    if (
      chatContainerRef.current.scrollTop === 0 &&
      currentPage < chatlist?.paginatorInfo?.lastPage
    ) {
      //scrollTop === 0
      fetchMoreData();
    }
  };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [isOpen, chatlist]);

  // sendMessage function when Enter is pressed
  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };
  const getChat = () => {
    setCurrentPage(1);
    setChatData([]);
    setIsListenerAdded(false);
    // dispatch(getMessages({ first: 10, page: 1 }));
    fetchData();
    setSupportClosedMessage();
  };
  const content = (
    <div className="h-[26rem] w-[320px] font-century-gothic">
      <div className="pb-2 flex justify-between items-center pt-3 ps-3 pe-1">
        <div className="flex">
          <div>
            <Badge color={"cyan"} dot={true} className="profileActiveBadge">
              <Avatar
                size={{
                  xs: 48,
                  sm: 48,
                  md: 48,
                  lg: 48,
                  xl: 48,
                  xxl: 48,
                }}
                src={superAdminlist?.GetSuperAdmin?.avatar || person}
              />
            </Badge>
          </div>
          <div className="flex flex-col justify-center ml-4">
            <div className="text-base">
              {superAdminlist?.GetSuperAdmin?.name}
            </div>
            <div className="text-gray-400">{"Support Agent"}</div>
          </div>
        </div>
        <div
          onClick={() => {
            setIsOpen(!isOpen);
            setScrollPosition(0);
            setUnreadChatCount(0);
            setCurrentPage(1);
            setSupportClosedMessage();
          }}
        >
          <i
            className="icon-cancel text-primary-red cursor-pointer"
            style={{ fontSize: "32px", cursor: "pointer" }}
          ></i>
        </div>
      </div>

      <div
        ref={chatContainerRef}
        className=" h-[73%] py-1 ps-1 pe-3 w-full overflow-y-scroll messageWrapper"
        onScroll={handleScroll}
      >
        {/* {chatloading && (
          <div className="flex justify-center items-center h-[73%]">
            <Loaders />
          </div>
        )} */}
        {supportClosedMessage !== null && supportClosedMessage?.length > 0 && (
          <div className="bg-yellow-100 text-yellow-500 p-3 rounded-lg mx-1">
            <b>Support Closed:</b> <br />
            {supportClosedMessage}
            <div className="flex justify-end ">
              {" "}
              <Button
                onClick={getChat}
                className="mt-3 text-red-600 bg-yellow-200 rounded-md"
              >
                Reconnect
              </Button>
            </div>
          </div>
        )}
        {!supportClosedMessage && (
          <>
            {chatData &&
              chatData?.length > 0 &&
              chatData?.map((message, index) => {
                return (
                  <div
                    key={index}
                    //ref={index === chatData?.length - 1 ? chatContainerRef : null}
                  >
                    {message?.isSender ? (
                      <div className="my-5">
                        <div
                          className={`w-full flex ${
                            message?.isSender ? "justify-end" : "justify-start"
                          }`}
                        >
                          <div
                            className={` text-break  max-w-[80%] p-2  ${
                              message?.isSender
                                ? "rounded-l-lg text-white bg-primary-red"
                                : "rounded-r-lg bg-[#FBFBFB]"
                            } rounded-b-lg  text-base `}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: parseMessage(message?.message),
                              }}
                            />
                          </div>
                        </div>
                        <div className="text-end text-xs text-gray-400 mt-1">
                          {parseTimestampToTime(message?.date) || "Just Now"}
                        </div>
                      </div>
                    ) : (
                      <div className="w-full ">
                        <div className="flex w-full max-w-[80%] p-2 rounded-r-lg text-break rounded-b-lg">
                          <div>
                            <Avatar
                              size={{
                                xs: 24,
                                sm: 24,
                                md: 28,
                                lg: 30,
                                xl: 30,
                                xxl: 30,
                              }}
                              className="img-fluid text-capitalize"
                              alt={"avatar"}
                              src={person}
                            />
                          </div>
                          <div className="ps-2 w-full">
                            <div className="mb-1">{message?.UserId}</div>
                            <div
                              className={`w-fit ${
                                message?.date !== undefined ? "p-2" : "p-0"
                              }  ${
                                message?.isSender
                                  ? "rounded-l-lg text-white bg-primary-red"
                                  : "rounded-r-lg bg-bg-secondary"
                              } rounded-b-lg  text-base text-gray-400`}
                            >
                              {message?.date !== undefined ? (
                                message?.message
                              ) : (
                                <img src={typing} height={35} alt="typing" />
                              )}
                            </div>
                            <div className="text-end text-xs text-gray-400 mt-1">
                              {parseTimestampToTime(message?.date) || ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            {chatlist?.user?.chat_support_req === false && (
              <>
                <div className="h-[73%] py-2 bg-gray-50 rounded-xl border messageWrapper border-gray-300 p-4 m-4">
                  <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item
                      name="name"
                      label="Your Name"
                      rules={[
                        { required: true, message: "Please input your name!" },
                      ]}
                    >
                      <Input placeholder="Your Name" className="rounded-full" />
                    </Form.Item>

                    <Form.Item
                      name="email"
                      label="Email Address"
                      rules={[
                        { required: true, message: "Please input your email!" },
                        { type: "email", message: "Please enter a valid email!" },
                      ]}
                    >
                      <Input placeholder="Email Address" className="rounded-full" />
                    </Form.Item>

                    <Form.Item
                      name="inquiry"
                      label="What brings you to Fischer today?"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your inquiry!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select an option"
                        className="custom-rounded-full"
                      >
                        <Select.Option value="inquiry">Inquiry</Select.Option>
                        <Select.Option value="feedback">Feedback</Select.Option>
                        <Select.Option value="issue">
                          Issue Resolution
                        </Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="assistanceDetails"
                      label="Briefly describe how we can assist you"
                      rules={[
                        {
                          required: true,
                          message: "Please enter additional details!",
                        },
                      ]}
                    >
                      <TextArea
                        placeholder="Enter additional details"
                        autoSize={{ minRows: 1, maxRows: 1 }}
                        className="rounded-full"
                      />
                    </Form.Item>

                    <Form.Item>
                      <div className="flex justify-end">
                        <Button
                          type="primary"
                          htmlType="submit"
                          shape="default"
                          loading={loading}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {chatlist?.user?.chat_support_req === true && (
        <div className="py-2 ps-2 pe-3 flex items-center justify-between bg-bg-secondary rounded-b-xl">
          <div className="pickerBox">
            <Button
              type="link"
              shape="circle"
              icon={
                <i
                  className="icon--smiley-emoji cursor-pointer iconMyBe text-gray-400"
                  style={{ fontSize: "24px", cursor: "pointer" }}
                ></i>
              }
              onClick={onClickOpenEmoji}
            />

            <EmojiPicker
              lazyLoadEmojis={true}
              open={isOpenEmoji}
              disableAutoFocus={true} // Disable autofocus to prevent automatic scrolling
              className="emojiPickerSt"
              onEmojiClick={onEmojiClick}
              searchDisabled
              // reactionsDefaultOpen={false}
            />
          </div>

          <TextArea
            value={value}
            className="chatTextArea"
            onChange={(e) => {
              setValue(e.target.value);
              setIsOpenEmoji(false); // Close emoji picker when typing
            }}
            onKeyDown={handleKeyDown}
            placeholder="Reply ..."
            size="large"
            autoSize={{
              minRows: 1,
              maxRows: 1,
            }}
          />
          <Button
            type="primary"
            shape="circle"
            // size="small"
            className="ml-2"
            icon={
              <RightOutlined style={{ fontSize: "14px", color: "white" }} />
            }
            onClick={sendMessage}
          />
        </div>
      )}
    </div>
  );

  const onClickFloatButton = () => {
    setUnreadChatCount(0);
    setCurrentPage(1);
    setIsOpen(!isOpen);
  };
  return (
    <div className="font-century-gothic messageOverlay">
      <Popover
        content={content}
        destroyTooltipOnHide={true}
        trigger={"click"}
        //onOpenChange={(e) => setIsOpen(e)}
        placement="topLeft"
        icon={false}
        arrow={false}
        open={isOpen}
        overlayClassName="popupChatBox"
      >
        <FloatButton
          trigger="click"
          type="primary"
          onClick={onClickFloatButton}
          badge={{
            count: isOpen ? 0 : unreadChatCount,
            size: "small",
            className: "top-[0%] right-[0%]",
          }}
          style={{
            right: 26,
            bottom: 24,
          }}
          className="h-12 w-12 messageFloatButton"
          icon={
            <i
              className="icon-chat cursor-pointer"
              style={{ fontSize: "24px", cursor: "pointer" }}
            ></i>
          }
        ></FloatButton>
      </Popover>
    </div>
  );
};

export default MessageOverlay;
