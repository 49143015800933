import { gql } from "@apollo/client";

export const REGISTER_PRODUCT = gql`
  mutation RegisterProduct(
    $categoryId: ID
    $serialNo: String!
    $productName: String!
    $dateOfPurchase: String!
  ) {
    RegisterProduct(
      input: {
        categoryId: $categoryId
        serialNo: $serialNo
        productName: $productName
        dateOfPurchase: $dateOfPurchase
      }
    ) {
      meta {
        status
        message
        code
      }
    }
  }
`;
