import { gql } from '@apollo/client';
export const ToggleNotification = gql`
  mutation ToggleNotificationPreference($notificationId: Int!) {
    ToggleNotificationPreference(
		notificationId: $notificationId
	){
      meta{
        status
        code
        message
      }
    }
  }`;

export const Add_DeviceToken = gql`
  mutation AddDeviceToken(
    $device_type: String!
    $device_id: String
    $device_token: String!
  ) {
    AddDeviceToken(
      input: {
        device_type: $device_type
        device_id: $device_id
        device_token: $device_token
      }
    ) {
      id
      device_token
    }
  }
`;

export const Mark_As_Read = gql`
  mutation MarkAsRead($device_id: String!) {
    MarkAsRead(device_id: $device_id) {
      meta {
        status
        message
        code
      }
    }
  }
`;
