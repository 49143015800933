import { createSlice } from "@reduxjs/toolkit";
import { addReview, getDashboardDetails, getOrderDetails, getOrdersList } from "./actions";
import { notification } from "antd";

const initialState = {
  getOrderlist: undefined,
  getOrderloading: false,
  getOrdererror: undefined,

  getOrderDetailslist: undefined,
  getOrderDetailsloading: false,
  getOrderDetailserror: undefined,

  addreviewlist: undefined,
  addreviewloading: false,
  addreviewerror: undefined,

  getDashboardDetailslist: undefined,
  getDashboardDetailsloading: false,
  getDashboardDetailserror: undefined,
};
const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    applyReview: (state, action) => {
      const idToDelete = action.payload;
      const filteredLine = state?.getOrderDetailslist?.lines?.filter(
        (line) => line?.product?.id === idToDelete
      );
      filteredLine.forEach((item) => {
        item.product.haveUserReviewed = true;
      });
      state.filteredOrderDetails = filteredLine;
    },
  },
  extraReducers: (builder) => {
    builder
      //Order List
      .addCase(getOrdersList.pending, (state) => {
        state.getOrderloading = true;
        state.getOrdererror = null;
      })
      .addCase(getOrdersList.fulfilled, (state, action) => {
        state.getOrderloading = false;
        state.getOrderlist = action.payload;
      })
      .addCase(getOrdersList.rejected, (state, action) => {
        state.getOrderloading = false;
        state.getOrdererror = action.payload;
      })

      .addCase(getOrderDetails.pending, (state) => {
        state.getOrderDetailsloading = true;
        state.getOrderDetailserror = null;
      })
      .addCase(getOrderDetails.fulfilled, (state, action) => {
        state.getOrderDetailsloading = false;
        state.getOrderDetailslist = action.payload;
      })
      .addCase(getOrderDetails.rejected, (state, action) => {
        state.getOrderDetailsloading = false;
        state.getOrderDetailserror = action.payload;
      })

      .addCase(addReview.pending, (state) => {
        state.addreviewloading = true;
        state.addreviewerror = null;
      })
      .addCase(addReview.fulfilled, (state, action) => {
        state.addreviewloading = false;
        state.addreviewlist = action.payload;
        notification.success({
          message: "Success",
          description: "Review Added Successfully",
          duration: 2,
        });
      })
      .addCase(addReview.rejected, (state, action) => {
        state.addreviewloading = false;
        state.addreviewerror = action.payload;
        notification.error({
          message: "Error",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })

      .addCase(getDashboardDetails.pending, (state) => {
        state.getDashboardDetailsloading = true;
        state.getDashboardDetailserror = null;
      })
      .addCase(getDashboardDetails.fulfilled, (state, action) => {
        state.getDashboardDetailsloading = false;
        state.getDashboardDetailslist = action.payload;

      })
      .addCase(getDashboardDetails.rejected, (state, action) => {
        state.getDashboardDetailsloading = false;
        state.getDashboardDetailserror = action.payload;

      });
  },
});
export const { applyReview } = orderSlice.actions;
export default orderSlice.reducer;
