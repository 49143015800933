import { gql } from '@apollo/client';
export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($id: ID!) {
    DeleteDocument(id: $id) {
      meta {
        status
        message
        code
      }
    }
  }
`;