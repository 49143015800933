import { gql } from '@apollo/client';

export const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    SignIn(input: {
      email: $email
      password: $password
    }){
			meta{
				status
				message
				code
			}
			user{
				id
				name
				company_request
				email
				avatar
				company_name
				is_verified
				profile_setup_status
				phone_number
				created_at
				updated_at
				notification_preferences{
					title
				}
				documents{
					client_original
					extension
					id
					mime_type
					name
					path
					size
					type
					created_at
					updated_at
				}
							}
			token{
				access_token
				token_type
			}
		}
  }
`;

export const GoogleLogin = gql`
mutation GoogleLogin($token: String!) {
	GoogleLogin(input: {
		token: $token
	}){
			meta{
			status
			message
			code
		}
		user{
			id
			name
			email
			created_at
			profile_setup_status
			is_verified
			notification_preferences{
				title
				info
				pivot{
					active
				}
			}
		}
		token{
			access_token
			token_type
			expires_in
		}
	}
}`;

export const FacebookLogin = gql`
mutation FacebookLogin($token: String!) {
	FacebookLogin(input: {
		token: $token
	}){
			meta{
			status
			message
			code
		}
		user{
			id
			name
			email
			created_at
			profile_setup_status
			is_verified
			notification_preferences{
				title
				info
				pivot{
					active
				}
			}
		}
		token{
			access_token
			token_type
			expires_in
		}
	}
}`;

