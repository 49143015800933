import { createSlice } from "@reduxjs/toolkit";
import { contactUs, findCMSSlug } from "./action";
import { notification } from "antd";

const initialState = {
  contactUslist: undefined,
  contactUsloading: false,
  contactUserror: undefined,

  findCMSSluglist: undefined,
  findCMSSlugloading: false,
  findCMSSlugerror: undefined,
};
const contactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contactUs.pending, (state) => {
        state.contactUsloading = true;
        state.contactUserror = null;
      })
      .addCase(contactUs.fulfilled, (state, action) => {
        state.contactUsloading = false;
        state.contactUslist = action.payload;
        notification.success({
            message: "Success",
             description: "Thanks for Submitting Form",
            duration: 2
          });
      })
      .addCase(contactUs.rejected, (state, action) => {
        state.contactUsloading = false;
        state.contactUserror = action.payload;
      })

      .addCase(findCMSSlug.pending, (state) => {
        state.findCMSSlugloading = true;
        state.findCMSSlugerror = null;
      })
      .addCase(findCMSSlug.fulfilled, (state, action) => {
        state.findCMSSlugloading = false;
        state.findCMSSluglist = action.payload;
      })
      .addCase(findCMSSlug.rejected, (state, action) => {
        state.findCMSSlugloading = false;
        state.findCMSSlugerror = action.payload;
      })
    }
});
export default contactUsSlice.reducer;