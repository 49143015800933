import { gql } from '@apollo/client';

export const ChangePassword = gql`
  mutation ChangePassword($old_password: String!, $new_password: String!, $confirm_password: String!) {
    ChangePassword(
      input: {
        old_password: $old_password
        new_password: $new_password
        confirm_password: $confirm_password
      }
    ) {
      meta {
        status
        message
        code
      }
    }
  }
`;