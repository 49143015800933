import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../../apollo-client";
import {
  Compose_CustomerSupportMail,
  Escalate_SupportTicket,
  Raise_SupportTicket,
} from "../../../Mutation/CustomerSupport/mutation";
import {
  All_SupportTickets,
  Get_SupportTicketById,
  Get_SupportTickets,
} from "../../../Query/CustomerSupport/query";

export const addTicket = createAsyncThunk(
  "user/addTicket",
  async (
    { product_name, serial_no, subject, message },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.mutate({
        mutation: Raise_SupportTicket,
        variables: {
          product_name,
          serial_no,
          subject,
          message,
        },
      });

      return response.data.RaiseSupportTicket;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getTicketList = createAsyncThunk(
  "user/getTicketList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.mutate({
        mutation: All_SupportTickets,
      });
      return response.data.AllSupportTickets;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getRaiseTicketList = createAsyncThunk(
  "user/getRaiseTicketList",
  async ({ first, page, type, status }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Get_SupportTickets,
        variables: { first, page, type, status },
      });
      return response.data.GetSupportTickets;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getSpecificTicketDetail = createAsyncThunk(
  "user/getSpecificTicketDetail",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Get_SupportTicketById,
        variables: { id },
      });
      return response.data.GetSupportTicketById;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const addComposeMail = createAsyncThunk(
  "user/addComposeMail",
  async (
    { product_name, serial_no, subject, message },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.mutate({
        mutation: Compose_CustomerSupportMail,
        variables: {
          product_name,
          serial_no,
          subject,
          message,
        },
      });

      return response.data.ComposeCustomerSupportMail;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const escalateRequest = createAsyncThunk(
  "user/escalateRequest",
  async ({ ticket_id, reason }, { rejectWithValue }) => {
    try {
      const response = await client.mutate({
        mutation: Escalate_SupportTicket,
        variables: {
          ticket_id,
          reason,
        },
      });

      return response.data.EscalateSupportTicket;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
