import { gql } from "@apollo/client";

export const Find_CmsBySlug = gql`
  query FindCmsBySlug($slug: String!) {
    FindCmsBySlug(slug: $slug) {
      id
      title
      content
    }
  }
`;
