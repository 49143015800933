import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import React, { Suspense, useEffect, useState } from "react";
import "./App.scss";
import { PublicRoutes, PrivateRoutes } from "./routes";
import Cookies from "js-cookie";
import "../src/assests/FischerIcons/css/fontello.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Footers from "./components/AuthLayout/Footers";
import Header from "./components/AuthLayout/Header";
import { v4 as uuidv4 } from "uuid";
import MessageOverlay from "./components/AuthLayout/MessageOverlay";
import Loaders from "./components/Loader";
import { Helmet } from "react-helmet";
function App() {
  const isVerifiedCookie = Cookies.get("isVerified");
  const isVerified =
  isVerifiedCookie !== "undefined" && isVerifiedCookie !== undefined
  ? JSON.parse(isVerifiedCookie)
  : false;
  
  const location = useLocation();
  const [documentTitleKey, setDocumentTitleKey] = useState(0); // State to control the key prop of Helmet
  const [currentTitle, setCurrentTitle] = useState("");
  const isVerifiedProfileCookie = Cookies.get("profileVerified");
  const profile_setup_status =
    isVerifiedProfileCookie !== "undefined" &&
    isVerifiedProfileCookie !== undefined
      ? JSON.parse(isVerifiedProfileCookie)
      : false;

  useEffect(() => {
    let deviceId = Cookies.get("deviceId");
    if (!deviceId) {
      deviceId = uuidv4();
      Cookies.set("deviceId", deviceId, { expires: 180 });
    }
  }, []);
  useEffect(() => {
    const currentRoute = [...PublicRoutes, ...PrivateRoutes]?.find(
      (route) => route.path === location.pathname
    );
    if (currentRoute && currentRoute?.title) {
      setCurrentTitle(currentRoute?.title);
      setDocumentTitleKey((prevKey) => prevKey + 1); // Increment the key to force re-rendering of Helmet
    }
  }, [location.pathname, PublicRoutes, PrivateRoutes]);

  const LoadingSpinner = () => (
    <div className="flex justify-center items-center h-screen">
      <Loaders />
    </div>
  );

  return (
    <Routes>
      {PublicRoutes.map(({ path, exact, component: Component, show }) => {
          return show ? (
            <Route
              key={path}
              path={path}
              exact={exact}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Helmet key={documentTitleKey}>
                    <title>{currentTitle}</title>
                  </Helmet>
                  {path === "/login" ||
                  path === "/register" ||
                  path === "/email" ||
                  path === "/forgot-password" ||
                  path === "/new-password" ||
                  path === "/user-detail" ? (
                    <Component></Component>
                  ) : (
                                          <div className="headerTopSpacing">
                        <Header />
                        <Component></Component>
                        <Footers />
                        {profile_setup_status && isVerified && <MessageOverlay />}
                      </div>
                                      )}
                </Suspense>
              }
            />
          ) : (
            <Route
              key={path}
              path={path}
              exact={exact}
              element={<Navigate to="/" />}
            />
          );
        })}
      {PrivateRoutes.map(({ path, exact, component: Component, show }) => {
        return isVerified && profile_setup_status ? (
          <>
            <Route
              key={path}
              path={path}
              exact={exact}
              element={
                <Suspense fallback={<LoadingSpinner />}>
                  <Helmet key={documentTitleKey}>
                    <title>{currentTitle}</title>
                  </Helmet>
                  <div className="pt-[76px]">
                    <Header />
                    <Component></Component>
                    <Footers />
                    <MessageOverlay />
                  </div>
                </Suspense>
              }
            />
          </>
        ) : isVerified === true && profile_setup_status === false ? (
          <Route
            key={path}
            path={path}
            exact={exact}
            element={<Navigate to="/user-detail" />}
          />
        ) : (
          <Route
            key={path}
            path={path}
            exact={exact}
            element={<Navigate to="/" />}
          />
        );
      })}
    </Routes>
  );
}

export default App;
