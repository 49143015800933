import { gql } from "@apollo/client";
export const GET_BANNERS = gql`
  query GetBanners($first: Int!, $page: Int!) {
    GetBanners(first: $first, page: $page) {
      meta {
        status
        message
        code
      }
      data {
        url
        order
        active
      }
      paginatorInfo {
        currentPage
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetProducts($first: Int!, $page: Int!, $str: String, $categories: [ID!],$type:GetProductsType!) {
    GetProducts(first: $first, page: $page, str: $str, categories: $categories,type:$type) {
      data {
        id
        name
        slug
        #sub_title
        formatted_price
        in_wishlist
        is_new
        is_available
        price {
          price
        }
        original_price
        #description
        #warranty_details
        #specifications
        #more_details
        thumbnail {
          name
          path
        }
        #images {
        #  name
        #  path
        #}
        #documents {
        #  name
        #  path
        #}
        #categories {
        #  id
        #  name
        #}
        offer {
          type
          value
          active
          label
        }
        #reviews {
        #  comment
        #  ratings
        #  user {
        #    avatar
        #  }
        #}
        product_variant{
          type
          id
          has_rewards
          reward_points
          goodies_points
          applicable_reward_points
        }
        cart_line {
          quantity
        }
        cart_details {
          min_limit
          adjust_count
          max_limit
        }
      }
      paginatorInfo {
        currentPage
        total
      }
    }
    user {
      user_reward_details {
        current_tier
        current_tier_image
        next_tier
        remaining_points_to_next_tier
      }
      name
      unread_messages
      total_reward_points
      total_reward_value
    }
  }
`;

export const GET_FAQ = gql`
  query GetFAQ {
    GetFAQ {
      id
      question
      answer
      order
    }
  }
`;

// export const GET_Testimonial = gql`
//   query GetHomepageReviews{
//     GetHomepageReviews{
//       comment
//       pin_to_homepage
//       ratings
//       created_at
//       user {
//           name
//           email
//           avatar
//       }
//     }
//   }
// `;

// export const GET_Testimonial = gql`
//   query GetHomepageReview($first: Int!, $page: Int!) {
//     GetHomepageReview(first: $first, page: $page) {
//       data {
//         comment
//         pin_to_homepage
//         created_at
//         user {
//           name
//           email
//           avatar
//         }
//         ratings
//       }
//       paginatorInfo {
//         currentPage
//       }
//     }
//   }
// `;

export const GET_Testimonial = gql`
  query GetTestimonials($first: Int!, $page: Int!) {
    GetTestimonials(first: $first, page: $page) {
      data {
        id
        name
        message
        image
        rating
        published_at
        created_at
      }
    }
  }
`;