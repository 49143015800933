import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../../apollo-client";
import { Create_ContactUs } from "../../../Mutation/ContactUs/mutation";
import { Find_CmsBySlug } from "../../../Query/CMSSlug/query";

export const contactUs = createAsyncThunk(
  "user/contactUs",
  async (
    {
      full_name,
      email,
      contact,
      house_number,
      address,
      postal_code,
      country_id,
      state_id,
      city_id,
      description,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.mutate({
        mutation: Create_ContactUs,
        variables: {
          full_name,
          email,
          contact,
          house_number,
          address,
          postal_code,
          country_id,
          state_id,
          city_id,
          description,
        },
      });

      return response.data.CreateContactUs;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const findCMSSlug = createAsyncThunk(
  "user/findCMSSlug",
  async ({ slug }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Find_CmsBySlug,
        variables: { slug },
      });
      return response.data.FindCmsBySlug;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
