import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
var firebaseConfig = {
  apiKey: "AIzaSyDU1Ynsj3NMi5qFUYhxqnsux-C622GDHos",
  authDomain: "circular-answer-410816.firebaseapp.com",
  projectId: "circular-answer-410816",
  storageBucket: "circular-answer-410816.appspot.com",
  messagingSenderId: "596846162186",
  appId: "1:596846162186:web:0d65916bf96302f3ea8af3",
  measurementId: "G-XK3FRXSL3K",
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BOCwoKC8wwabv98Y5s2E008TWE4f-R6MfUq1pV48Djpt0lig-uRQDeu6ChTLPiIYQ_C0aj-cbbBsA0FPsYFmN3U",
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const receiveMessage = () => {
  return onMessage(messaging, (payload) => {
    const notificationTitle = payload?.notification?.title;
    const notificationOptions = {
      body: payload?.notification?.body,
    };
    new Notification(notificationTitle, notificationOptions);
    //self.registration.showNotification(notificationTitle, notificationOptions);
  });
};
