import { createSlice } from "@reduxjs/toolkit";
import {
  addDeviceToken,
  getNotifications,
  markAsRead,
  notifications,
} from "./actions";
import { notification } from "antd";

const initialState = {
  notificationList: null,
  notificationError: null,
  notificationLoading: false,

  getnotificationList: undefined,
  getnotificationError: undefined,
  getnotificationLoading: false,

  deviceList: undefined,
  deviceError: undefined,
  deviceLoading: false,

  readnotificationList: undefined,
  readnotificationError: undefined,
  readnotificationLoading: false,
};
const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    newNotificationList: (state, action) => {
      state.notificationList = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(notifications.pending, (state) => {
        state.notificationLoading = true;
        state.notificationError = null;
      })
      .addCase(notifications.fulfilled, (state, action) => {
        state.notificationLoading = false;
        state.notificationList = action.payload;
        notification.success({
          message: "Preference Change",
          description: action?.payload?.meta?.message,
          duration: 2,
        });
      })
      .addCase(notifications.rejected, (state, action) => {
        state.notificationLoading = false;
        state.notificationError = action.payload;
        notification.error({
          message: "Error Change Preference",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })
      .addCase(addDeviceToken.pending, (state) => {
        state.deviceLoading = true;
        state.deviceError = null;
      })
      .addCase(addDeviceToken.fulfilled, (state, action) => {
        state.deviceLoading = false;
        state.deviceList = action.payload;
      })
      .addCase(addDeviceToken.rejected, (state, action) => {
        state.deviceLoading = false;
        state.deviceError = action.payload;
      })

      .addCase(markAsRead.pending, (state) => {
        state.readnotificationLoading = true;
        state.readnotificationError = null;
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        state.readnotificationLoading = false;
        state.readnotificationList = action.payload;
        notification.success({
          message: "Success",
          description: action?.payload?.meta?.message,
          duration: 2,
        });
      })
      .addCase(markAsRead.rejected, (state, action) => {
        state.readnotificationLoading = false;
        state.readnotificationError = action.payload;
        notification.error({
          message: "Error",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })

      .addCase(getNotifications.pending, (state) => {
        state.getnotificationLoading = true;
        state.getnotificationError = null;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.getnotificationLoading = false;
        state.getnotificationList = action.payload;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.getnotificationLoading = false;
        state.getnotificationError = action.payload;
        notification.error({
          message: "Error",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      });
  },
});
export const { newNotificationList } = notificationSlice.actions;
export default notificationSlice.reducer;
