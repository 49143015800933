import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../../apollo-client";
import { Get_DashboardDetails, Get_OrderDetails, Get_OrdersList } from "../../../Query/Order/query";
import { Add_Review } from "../../../Mutation/Review/mutation";

export const getOrdersList = createAsyncThunk(
  "product/getOrdersList",
  async ({ first, page, status }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Get_OrdersList,
        variables: { first, page, status },
      });
      return response.data.GetOrdersList;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getOrderDetails = createAsyncThunk(
  'product/getOrderDetails',
  async (serial_no, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Get_OrderDetails,
        variables: { serial_no: serial_no },
      });
      return response.data.GetOrderDetails;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const addReview = createAsyncThunk(
  "product/addReview",
  async ({ product_id, ratings, comment }, { rejectWithValue }) => {
    try {
      const response = await client.mutate({
        mutation: Add_Review,
        variables: {
          product_id: product_id,
          ratings: ratings,
          comment: comment,
        },
      });
      return response.data.AddReview;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getDashboardDetails = createAsyncThunk(
  'dashboard/getDashboardDetails',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Get_DashboardDetails,
      });
      return response.data.GetDashboardDetails;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
