import { gql } from "@apollo/client";
export const Verify_ForgotPassword_Otp = gql`
query VerifyForgotPasswordOtp($otp: String!,$email:String!) {
    VerifyForgotPasswordOtp(input: {
        email:$email
        otp: $otp
    }) {
        meta{
            status
            message
            code
        }
        userId
    }
}
`;