import React from "react";
import Loader from "react-js-loader";
export default function Loaders() {
  return (
    <div>
      <Loader
        type="spinner-cub"
        bgColor={"#E60C19"}
        title={"spinner-cub"}
        size={50}
      />
    </div>
  );
}
