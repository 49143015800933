import React from "react";
import "./index.scss";
import ReactDOM from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./services/Store";
import client from "./apollo-client";
import { ConfigProvider, theme } from "antd";
import antdconfig from "./ant-config";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          ...antdconfig,
          //fontFamily: 'CenturyGothic'
          // algorithm: darkAlgorithm ,
        }}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  </ApolloProvider>
  // </React.StrictMode>,
);

reportWebVitals();
