import { gql } from "@apollo/client";
export const Verify_Account = gql`
  query VerifyAccount($userId: ID!, $otp: String!) {
    VerifyAccount(input: { userId: $userId, otp: $otp }) {
      meta {
        status
        message
        code
      }
      token {
        access_token
        token_type
        expires_in
      }
      user {
        id
        email
        is_verified
        profile_setup_status
      }
    }
  }
`;

export const Resend_OTP = gql`
  query ResendOTP($userId: ID!) {
    ResendOTP(userId: $userId) {
      meta {
        status
        message
        code
      }
    }
  }
`;

export const Get_Counties = gql`
  query GetCounties {
    GetCounties {
      id
      name
      country_code
      status
    }
  }
`;

export const Get_State = gql`
  query GetStates($country_id: ID!) {
    GetStates(country_id: $country_id) {
      id
      name
      state_code
      country_id
      status
    }
  }
`;

export const Get_City = gql`
  query GetCities($state_id: ID!) {
    GetCities(state_id: $state_id) {
      id
      name
      country_id
      state_id
      status
    }
  }
`;
