import { gql } from '@apollo/client';

export const Forgot_Password = gql`
  mutation ForgotPassword($email: String!) {
    ForgotPassword(
      input: {
        email: $email
      }
    ) {
      meta {
        status
        message
        code
      }
    }
  }
`;
export const Update_Forgot_Password = gql`
  mutation UpdateForgotPassword($userId: ID!, $newPassword: String!) {
    UpdateForgotPassword(userId: $userId,
      input: { new_password: $newPassword }) {
      meta {
        status
        message
        code
      }
    }
}`;