import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../../apollo-client";
import {
  Apply_CheckoutDetails,
  Find_CMS,
  Get_RewardPointsHistories,
} from "../../../Query/Reward Point/query";

export const getRewardPoints = createAsyncThunk(
  "product/getRewardPoints",
  async (
    { checkoutEntity, checkoutId, applyRewardPoints },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.query({
        query: Apply_CheckoutDetails,
        variables: { checkoutEntity, checkoutId, applyRewardPoints },
      });
      return response.data.ApplyCheckoutDetails;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getRewardPointsHistories = createAsyncThunk(
  "user/getRewardPointsHistories",
  async ({ first, page ,type}, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Get_RewardPointsHistories,
        variables: { first, page ,type},
      });
      return response.data.GetRewardPointsHistories;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getAboutUs = createAsyncThunk(
  "user/getAboutUs",
  async ({ type }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Find_CMS,
        variables: { type },
      });
      return response.data.FindCMS;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
