import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../../apollo-client";
import { Get_Documents } from "../../../Query/Documents/query";
import { DELETE_DOCUMENT } from "../../../Mutation/Documents/mutation";

export const getDocuments = createAsyncThunk(
    'user/getDocuments',
    async ({ first, page, search }, { rejectWithValue }) => {
        try {
            const response = await client.query({
                query: Get_Documents,
                variables: { first, page, search },
            });
            return response.data.GetDocuments;
        } catch (error) {
            throw rejectWithValue(error);
        }
    });

export const deleteDocument = createAsyncThunk(
    'user/deleteDocument',
    async (id, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: DELETE_DOCUMENT,
                variables: { id },
            });
            return response.data.DeleteDocument.meta;
        } catch (error) {
            throw rejectWithValue(error);
        }
    }
);