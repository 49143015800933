import { createSlice } from "@reduxjs/toolkit";
import { Verify_ForgotPassword, forgetPassword, update_ForgotPassword } from "./actions";
import { notification } from "antd";

const initialState = {
    forgetloading: false,
    forgeterror: null,
    forgetlist: null,

    verifyPasswordloading: false,
    verifyPassworderror: null,
    verifyPasswordList: null,

    newPasswordList: null,
    newPassworderror: null,
    newPasswordloading: false,
}
const forgetSlice = createSlice({
    name: 'forgetpassword',
    initialState,
    reducers: {
        newVerifyPasswordList: (state, action) => {
            state.verifyPasswordList = null;
        },
        newForgetPasswordList: (state, action) => {
            state.forgetlist = null;
        },
        newUpdatePasswordList: (state, action) => {
            state.newPasswordList = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(forgetPassword.pending, (state) => {
                state.forgetloading = true;
                state.forgeterror = null;
            })
            .addCase(forgetPassword.fulfilled, (state, action) => {
                state.forgetloading = false;
                state.forgetlist = action.payload;
                notification.success({
                    message: 'OTP Send',
                    description: action?.payload?.meta?.message,
                    duration: 2
                });
            })
            .addCase(forgetPassword.rejected, (state, action) => {
                state.forgetloading = false;
                state.forgeterror = action.payload;
                notification.error({
                    message: 'Operation Failed',
                    description: action?.payload?.graphQLErrors[0]?.message,
                    duration: 2
                });
            })

            .addCase(Verify_ForgotPassword.pending, (state) => {
                state.verifyPasswordloading = true;
                state.verifyPassworderror = null;
            })
            .addCase(Verify_ForgotPassword.fulfilled, (state, action) => {
                state.verifyPasswordloading = false;
                state.verifyPasswordList = action.payload;
                notification.success({
                    message: 'OTP Verified',
                    description: action?.payload?.meta?.message,
                });
            })
            .addCase(Verify_ForgotPassword.rejected, (state, action) => {
                state.verifyPasswordloading = false;
                state.verifyPassworderror = action.payload;
                notification.error({
                    message: 'OTP Verified Failed',
                    description: action?.payload?.graphQLErrors[0]?.message,
                });
            })

            .addCase(update_ForgotPassword.pending, (state) => {
                state.newPasswordloading = true;
                state.newPassworderror = null;
            })
            .addCase(update_ForgotPassword.fulfilled, (state, action) => {
                state.newPasswordloading = false;
                state.newPasswordList = action.payload;
                notification.success({
                    message: 'Password Changed',
                    description: action?.payload?.meta?.message,
                });
            })
            .addCase(update_ForgotPassword.rejected, (state, action) => {
                state.newPasswordloading = false;
                state.newPassworderror = action.payload;
                notification.error({
                    message: 'Password Failed',
                    description: action?.payload?.graphQLErrors[0]?.message,
                });
            })
    },
});
export const { newVerifyPasswordList, newForgetPasswordList, newUpdatePasswordList } = forgetSlice.actions;
export default forgetSlice.reducer;