import { gql } from '@apollo/client';
export const SIGN_UP = gql`
  mutation SignUp($email: String!, $password: String!) {
    SignUp(input: {
      email: $email
      password: $password
    }){
		meta{
			status
			message
			code
		}
		user{
			id
			name
			email
			created_at
			profile_setup_status
			notification_preferences{
				title
				info
				pivot{
					active
				}
			}
		}
	}
  }
`;

export const SELECT_REGISTER_TYPE = gql`
  mutation SelectRegistrationType($registration_type: RegistrationTypeEnum!, $company_vat_number: String,$company_registration_number:String) {
    SelectRegistrationType(input: {
      registration_type: $registration_type
      company_vat_number: $company_vat_number
	  company_registration_number:$company_registration_number
    }){
		meta {
			status
			message
			code
		}
	}
}`;
