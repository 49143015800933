import { createSlice } from "@reduxjs/toolkit";
import {
  addComposeMail,
  addTicket,
  escalateRequest,
  getRaiseTicketList,
  getSpecificTicketDetail,
  getTicketList,
} from "./action";
import { notification } from "antd";
const initialState = {
  raiseticketlist: undefined,
  raiseticketloading: false,
  raiseticketerror: undefined,

  ticketlist: undefined,
  ticketloading: false,
  ticketerror: undefined,

  raiseAllticketlist: undefined,
  raiseAllticketloading: false,
  raiseAllticketerror: undefined,

  getSpecificTicketlist: undefined,
  getSpecificTicketloading: false,
  getSpecificTicketerror: undefined,

  addcomposemaillist: undefined,
  addcomposemailloading: false,
  addcomposemailerror: undefined,

  escalateRequest: undefined,
  escalateRequestloading: false,
  escalateRequesterror: undefined,
};
const customersupportSlice = createSlice({
  name: "customersupport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addTicket.pending, (state) => {
        state.raiseticketloading = true;
        state.raiseticketerror = null;
      })
      .addCase(addTicket.fulfilled, (state, action) => {
        state.raiseticketloading = false;
        state.raiseticketlist = action.payload;
      })
      .addCase(addTicket.rejected, (state, action) => {
        state.raiseticketloading = false;
        state.raiseticketerror = action.payload;
        notification.error({
          message: "Error",
          description: action?.payload?.graphQLErrors[0]?.message,
        });
      })

      .addCase(getTicketList.pending, (state) => {
        state.ticketloading = true;
        state.ticketerror = null;
      })
      .addCase(getTicketList.fulfilled, (state, action) => {
        state.ticketloading = false;
        state.ticketlist = action.payload;
      })
      .addCase(getTicketList.rejected, (state, action) => {
        state.ticketloading = false;
        state.ticketerror = action.payload;
      })

      .addCase(getRaiseTicketList.pending, (state) => {
        state.raiseAllticketloading = true;
        state.raiseAllticketerror = null;
      })
      .addCase(getRaiseTicketList.fulfilled, (state, action) => {
        state.raiseAllticketloading = false;
        state.raiseAllticketlist = action.payload;
      })
      .addCase(getRaiseTicketList.rejected, (state, action) => {
        state.raiseAllticketloading = false;
        state.raiseAllticketerror = action.payload;
      })

      .addCase(getSpecificTicketDetail.pending, (state) => {
        state.getSpecificTicketloading = true;
        state.getSpecificTicketerror = null;
      })
      .addCase(getSpecificTicketDetail.fulfilled, (state, action) => {
        state.getSpecificTicketloading = false;
        state.getSpecificTicketlist = action.payload;
      })
      .addCase(getSpecificTicketDetail.rejected, (state, action) => {
        state.getSpecificTicketloading = false;
        state.getSpecificTicketerror = action.payload;
      })

      .addCase(addComposeMail.pending, (state) => {
        state.addcomposemailloading = true;
        state.addcomposemailerror = null;
      })
      .addCase(addComposeMail.fulfilled, (state, action) => {
        state.addcomposemailloading = false;
        state.addcomposemaillist = action.payload;
      })
      .addCase(addComposeMail.rejected, (state, action) => {
        state.addcomposemailloading = false;
        state.addcomposemailerror = action.payload;
        notification.error({
          message: "Error",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })

      .addCase(escalateRequest.pending, (state) => {
        state.escalateRequestloading = true;
        state.escalateRequesterror = null;
      })
      .addCase(escalateRequest.fulfilled, (state, action) => {
        state.escalateRequestloading = false;
        state.escalateRequest = action.payload;
      })
      .addCase(escalateRequest.rejected, (state, action) => {
        state.escalateRequestloading = false;
        state.escalateRequesterror = action.payload;
        notification.error({
          message: "Error",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      });
  },
});
export default customersupportSlice.reducer;
