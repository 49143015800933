import { createSlice } from "@reduxjs/toolkit";
import { getWishlist, manageWishlist } from "./actions";
import { notification } from "antd";

const initialState = {
  wishlistloading: false,
  wishlisterror: null,
  wishlistlist: undefined,

  getwishlistloading: false,
  getwishlisterror: null,
  getwishlistlist: undefined,
}
const wishListSlice = createSlice({
  name: 'wishList',
  initialState,
  reducers: {
    updatewishlist: (state, action) => {
      const { productId } = action.payload;
      const updatedWishlist = state.getwishlistlist.data.filter(item => item.product.id !== productId);
      return {
        ...state,
        getwishlistlist: {
          ...state.getwishlistlist,
          data: updatedWishlist
                }
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(manageWishlist.pending, (state) => {
        state.wishlistloading = true;
        state.wishlisterror = null;
      })
      .addCase(manageWishlist.fulfilled, (state, action) => {
        state.wishlistloading = false;
        state.wishlistlist = action.payload;
      })
      .addCase(manageWishlist.rejected, (state, action) => {
        state.wishlistloading = false;
        state.wishlisterror = action.payload;
        notification.error({
          message: "Remove to WishList",
          description:  action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })

      .addCase(getWishlist.pending, (state) => {
        state.getwishlistloading = true;
        state.getwishlisterror = null;
      })
      .addCase(getWishlist.fulfilled, (state, action) => {
        state.getwishlistloading = false;
        state.getwishlistlist = action.payload;
      })
      .addCase(getWishlist.rejected, (state, action) => {
        state.getwishlistloading = false;
        state.getwishlisterror = action.payload;
      })
  },
});
export const { updatewishlist } = wishListSlice.actions;
export default wishListSlice.reducer;