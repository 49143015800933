import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../../apollo-client";
import { Manage_Wishlist } from "../../../Mutation/WishList/mutation";
import { GET_WISHLIST } from "../../../Query/WishList/query";

export const manageWishlist = createAsyncThunk(
    'product/manageWishlist',
    async (productId, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: Manage_Wishlist,
                variables: { productId },
            });
            return response.data.ManageWishlist;
        } catch (error) {
            throw rejectWithValue(error);
        }
    }
);

export const getWishlist = createAsyncThunk(
    'product/getWishlist',
    async ({ first, page }, { rejectWithValue }) => {
        try {
            const response = await client.query({
                query: GET_WISHLIST,
                variables: { first, page },
            });
            return response.data.GetWishlist;
        } catch (error) {
            throw rejectWithValue(error);
        }
    });