import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../../apollo-client";
import { Get_SuperAdmin } from "../../../Query/SuperAdmin/query";

export const superAdmin = createAsyncThunk(
  "user/superAdmin",
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Get_SuperAdmin,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
