import { createSlice } from "@reduxjs/toolkit";
import { superAdmin } from "./actions";

const initialState = {
  superAdminloading: false,
  superAdminerror: null,
  superAdminlist: undefined,
};
const superAdminSlice = createSlice({
  name: "superAdmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(superAdmin.pending, (state) => {
        state.superAdminloading = true;
        state.superAdminerror = null;
      })
      .addCase(superAdmin.fulfilled, (state, action) => {
        state.superAdminloading = false;
        state.superAdminlist = action.payload;
      })
      .addCase(superAdmin.rejected, (state, action) => {
        state.superAdminloading = false;
        state.superAdminerror = action.payload;
      });
  },
});
export default superAdminSlice.reducer;
