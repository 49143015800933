import { createSlice } from "@reduxjs/toolkit";
import { fetchProductDetails, fetchProducts, fetchroductDetailsBySlug, getCategories, getRandomProducts, getRegisterProducts, getSimilarProducts,
} from "./actions";

const initialState = {
  productlist: null,
  productloading: false,
  producterror: null,

  slugproductlist: undefined,
  slugproductloading: false,
  slugproducterror: undefined,

  categorieslist: null,
  categoriesloading: false,
  categorieserror: null,

  specificproductlist: null,
  specificproductloading: false,
  specificproducterror: null,

  randomproductlist: null,
  randomproductloading: false,
  randomproducterror: null,

  similarproductlist: null,
  similarproductloading: false,
  similarproducterror: null,

  registerproductlist: undefined,
  registerproductloading: false,
  registerproducterror: undefined,
};
const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchProducts.pending, (state) => {
        state.productloading = true;
        state.producterror = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.productloading = false;
        state.productlist = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.productloading = false;
        state.producterror = action.payload;
      })

      //Categories
      .addCase(getCategories.pending, (state) => {
        state.categoriesloading = true;
        state.categorieserror = null;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categoriesloading = false;
        state.categorieslist = action.payload;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.categoriesloading = false;
        state.categorieserror = action.payload;
      })

      //productDetails
      .addCase(fetchProductDetails.pending, (state) => {
        state.specificproductloading = true;
        state.specificproducterror = null;
      })
      .addCase(fetchProductDetails.fulfilled, (state, action) => {
        state.specificproductloading = false;
        state.specificproductlist = action.payload;
      })
      .addCase(fetchProductDetails.rejected, (state, action) => {
        state.specificproductloading = false;
        state.specificproducterror = action.payload;
      })

      // fetch slug product detail
      .addCase(fetchroductDetailsBySlug.pending, (state) => {
        state.slugproductloading = true;
        state.slugproducterror = null;
      })
      .addCase(fetchroductDetailsBySlug.fulfilled, (state, action) => {
        state.slugproductloading = false;
        state.slugproductlist = action.payload;
      })
      .addCase(fetchroductDetailsBySlug.rejected, (state, action) => {
        state.slugproductloading = false;
        state.slugproducterror = action.payload;
      })
      //Random Product
      .addCase(getRandomProducts.pending, (state) => {
        state.randomproductloading = true;
        state.randomproducterror = null;
      })
      .addCase(getRandomProducts.fulfilled, (state, action) => {
        state.randomproductloading = false;
        state.randomproductlist = action.payload;
      })
      .addCase(getRandomProducts.rejected, (state, action) => {
        state.randomproductloading = false;
        state.randomproducterror = action.payload;
      })

      //Similar Product
      .addCase(getSimilarProducts.pending, (state) => {
        state.similarproductloading = true;
        state.similarproducterror = null;
      })
      .addCase(getSimilarProducts.fulfilled, (state, action) => {
        state.similarproductloading = false;
        state.similarproductlist = action.payload;
      })
      .addCase(getSimilarProducts.rejected, (state, action) => {
        state.similarproductloading = false;
        state.similarproducterror = action.payload;
      })

      //Register Product
      .addCase(getRegisterProducts.pending, (state) => {
        state.registerproductloading = true;
        state.registerproducterror = null;
      })
      .addCase(getRegisterProducts.fulfilled, (state, action) => {
        state.registerproductloading = false;
        state.registerproductlist = action.payload;
      })
      .addCase(getRegisterProducts.rejected, (state, action) => {
        state.registerproductloading = false;
        state.registerproducterror = action.payload;
      });
  },
});
export const { updateRewardsPointProductDetailsList } = productSlice.actions;
export default productSlice.reducer;