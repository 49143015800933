import { gql } from '@apollo/client';
export const Manage_Wishlist = gql`
mutation ManageWishlist($productId:ID!){
    ManageWishlist(productId:$productId)
{
        meta{
					status
					message
					code
				}
				wishlist{
					user{
						name
						wishlist_count
					}
					product{
						name
					}
				}
    }
}`;