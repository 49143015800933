import { gql } from "@apollo/client";
export const Get_SuperAdmin = gql`
  query GetSuperAdmin {
    GetSuperAdmin {
      user_reward_details {
        current_tier
        current_tier_image
        next_tier
        remaining_points_to_next_tier
      }
      total_reward_points
      total_reward_value
      current_tier_position
      id
      avatar
      name
      avatar
      company_name
      created_at
      email
      email_verified_at
      id
      is_verified
      phone_number
      profile_setup_status
      updated_at
      notification_preferences {
        title
        id
        info
        pivot {
          active
        }
      }
    }
  }
`;
