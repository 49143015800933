import { createAsyncThunk } from '@reduxjs/toolkit';
import client from "../../../apollo-client";
import { GET_BANNERS } from '../../../Query/HomePage/query';
export const fetchBanners = createAsyncThunk(
    'banners/getBanners',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await client.query({
                query: GET_BANNERS,
                variables: { first: 10, page: 1 },
            });
            return response.data.GetBanners.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);