import { gql } from "@apollo/client";
export const GET_WISHLIST = gql`
  query GetWishlist($first: Int!, $page: Int!) {
    GetWishlist(first: $first, page: $page) {
      data{
        user{
          name
          wishlist_count
        }
        product{
          id
          name
          slug
          sub_title
          formatted_price
          in_wishlist
          is_new
          is_available
          price {
            price
          }
          original_price
          thumbnail {
            name
            path
          }
          images {
            name
            path
          }
          offer {
            type
            value
            active
            label
          }
          product_variant {
            type
            id
            has_rewards
            reward_points
            goodies_points
            applicable_reward_points
          }
          cart_details {
            min_limit
            adjust_count
            max_limit
          }
        }
      }
      paginatorInfo {
        currentPage
        total
      }
    }
  }`;