import { gql } from "@apollo/client";

export const Get_OrdersList = gql`
  query GetOrdersList($first: Int!, $page: Int!, $status: String) {
    GetOrdersList(first: $first, page: $page, status: $status) {
      data {
        id
        serial_no
        user {
          id
          name
        }
        status
        reference
        sub_total
        discount_total
        shipping_total
        total
        notes
        placed_at
        formatted_expected_delivery_date
        #apply_reward_points
        formatted_details {
          sub_total
          total_selling_price
          total
          total_discount
          total_shipping_charges
          total_discount_price
          #total_reward_points
          total_reward_value
          total_earned_points
        }
        order_address {
          full_address
        }
        lines {
          id
          type
          identifier
          unit_price
          unit_quantity
          quantity
          sub_total
          discount_total
          tax_total
          total
          notes
          formatted_details {
            discount_price
            shipping_total
            sub_total
            total
          }
          created_at
          updated_at
        }
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;

export const Get_OrderDetails = gql`
  query GetOrdersList($serial_no: String!) {
    GetOrderDetails(serial_no: $serial_no) {
      id
      serial_no
      user {
        id
        name
      }
      status
      reference
      sub_total
      discount_total
      shipping_total
      total
      total
      notes
      placed_at
      formatted_expected_delivery_date
      #apply_reward_points
      formatted_details {
        sub_total
        total_selling_price
        total
        total_discount
        total_shipping_charges
        total_discount_price
        total_reward_points
        total_reward_value
        total_earned_points
        total_goodies_points
        total_applied_reward_points
        applied_rewards_value_label
      }
      order_address {
        id
        title
        house_number
        address
        postal_code
        is_default
        full_address
        city {
          name
        }
        state {
          name
        }
        country {
          name
        }
      }
      lines {
        id
        line_items {
          serial_no
        }
        type
        identifier
        unit_price
        #                     unit_quantity
        product{
          id
          name
          haveUserReviewed
          slug
          thumbnail {
            name
            path
          }
        }
        quantity
        sub_total
        discount_total
        shipping_total
        #                     tax_total
        total
        #                     notes
        formatted_details {
          discount_price
          shipping_total
          sub_total
          total
        }
        created_at
        updated_at
        formatted_details {
          original_price
          discount_price
          goodies_points
        }
        meta {
          product {
            id
            name
            sub_title
          }
          product_variant {
            type
            id
            has_rewards
            reward_points
            goodies_points
            applicable_reward_points
          }
        }
      }
      order_address {
        full_address
      }
    }
  }
`;

export const Get_DashboardDetails = gql`
  query GetDashboardDetails {
    GetDashboardDetails {
      available_points
      total_purchased_products
      total_raised_tickets
    }
  }
`;
