import { gql } from "@apollo/client";
export const SEND_MESSAGE = gql`
  mutation SendMessage($message: String!, $chat_support_req: Boolean) {
    SendMessage(message: $message, chat_support_req: $chat_support_req) {
      id
      message
      created_at
      from {
        id
        name
      }
      to {
        id
        name
      }
    }
  }
`;
