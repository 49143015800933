import { createSlice } from "@reduxjs/toolkit";
import {
  addCheckoutAddress,
  addToCart,
  checkoutPaymentStatus,
  getCartDetails,
  removeFromCart,
} from "./actions";
import { notification } from "antd";

const initialState = {
  cartlist: undefined,
  cartloading: false,
  carterror: null,

  cartDetailsList: undefined,
  cartDetailsloading: false,
  cartDetailserror: null,

  addcheckoutAddressList: undefined,
  addcheckoutAddressloading: false,
  addcheckoutAddresserror: undefined,

  checkoutPaymentStatusList: undefined,
  checkoutPaymentStatusloading: false,
  checkoutPaymentStatuserror: null,

  removecartlist: undefined,
  removecartloading: false,
  removecarterror: undefined,
};
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    updateRewardsPointCartDetailsList: (state, action) => {
      const { order_total, apply_reward_points, total_reward_value,total_used_points,remaining_amount_to_pay} =
        action.payload.responseData;
      state.cartDetailsList.formatted_details.formatted_remaining_amount_to_pay = remaining_amount_to_pay;
      state.cartDetailsList.formatted_details.formatted_total_used_points = total_used_points;
      state.cartDetailsList.formatted_details.formatted_total = order_total;
      state.cartDetailsList.formatted_details.total_reward_points =
        apply_reward_points;
      state.cartDetailsList.formatted_details.formatted_total_reward_value =
        total_reward_value;
    },

    updateaddtoCartDetailsList: (state, action) => {
      state.cartDetailsList = action.payload;
    },
    updateOutofStockProduct: (state, action) => {
      state.addcheckoutAddresserror = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToCart.pending, (state) => {
        state.cartloading = true;
        state.carterror = null;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.cartloading = false;
        state.cartlist = action.payload;
        if (action?.payload?.meta?.message === "Product added to cart." && action?.payload?.showToast) {
          notification.success({
            message: 'Add to Cart',
            description: action?.payload?.meta?.message,
            duration: 2,
          })
        }
        if (action?.payload?.meta?.message === "Product removed from cart." && action?.payload?.showToast) {
          notification.success({
            message: 'Remove to Cart',
            description: action?.payload?.meta?.message,
            duration: 2
          })
        }
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.cartloading = false;
        state.carterror = action;
        notification.error({
          message:
            action?.payload?.graphQLErrors[0]?.extensions?.movement === "add"
              ? "Failed to Add to Cart"
              : "Failed Remove from Cart",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2
        });
      })

      .addCase(getCartDetails.pending, (state) => {
        state.cartDetailsloading = true;
        state.cartDetailserror = null;
      })
      .addCase(getCartDetails.fulfilled, (state, action) => {
        state.cartDetailsloading = false;
        state.cartDetailsList = action.payload;
      })
      .addCase(getCartDetails.rejected, (state, action) => {
        state.cartDetailsloading = false;
        state.cartDetailserror = action;
      })

      .addCase(removeFromCart.pending, (state) => {
        state.removecartloading = true;
        state.removecarterror = null;
      })
      .addCase(removeFromCart.fulfilled, (state, action) => {
        state.removecartloading = false;
        state.removecartlist = action.payload;
        notification.success({
          message: action?.payload?.meta?.message,
          duration: 2,
        });
      })
      .addCase(removeFromCart.rejected, (state, action) => {
        state.removecartloading = false;
        state.removecarterror = action;
        notification.error({
          message: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })

      .addCase(addCheckoutAddress.pending, (state) => {
        state.addcheckoutAddressloading = true;
        state.addcheckoutAddresserror = null;
      })
      .addCase(addCheckoutAddress.fulfilled, (state, action) => {
        state.addcheckoutAddressloading = false;
        state.addcheckoutAddressList = action.payload;
        notification.success({
          message: action?.payload?.meta?.message,
          duration: 2
        });
      })
      .addCase(addCheckoutAddress.rejected, (state, action) => {
        state.addcheckoutAddressloading = false;
        state.addcheckoutAddresserror = action;
      })

      .addCase(checkoutPaymentStatus.pending, (state) => {
        state.checkoutPaymentStatusloading = true;
        state.checkoutPaymentStatuserror = null;
      })
      .addCase(checkoutPaymentStatus.fulfilled, (state, action) => {
        state.checkoutPaymentStatusloading = false;
        state.checkoutPaymentStatusList = action.payload;
        notification.success({
          message: action?.payload?.meta?.message,
          duration: 2
        });
      })
      .addCase(checkoutPaymentStatus.rejected, (state, action) => {
        state.checkoutPaymentStatusloading = false;
        state.checkoutPaymentStatuserror = action;
        notification.error({
          message: action?.payload?.graphQLErrors[0]?.message,
          duration: 2
      });
})
  },
});
export const {
  updateCartDetailsList,
  updateaddtoCartDetailsList,
  updateOutofStockProduct,
  updateRewardsPointCartDetailsList,
} = cartSlice.actions;
export default cartSlice.reducer;
