import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../../apollo-client";
import { Forgot_Password, Update_Forgot_Password } from "../../../Mutation/ForgetPassword/mutation";
import { Verify_ForgotPassword_Otp } from "../../../Query/ForgetPassword/query";

export const forgetPassword = createAsyncThunk(
    'user/forgetPassword',
    async ({ email }, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: Forgot_Password,
                variables: {
                    email,
                },
            });
            return response.data.ForgotPassword;
        } catch (error) {
            throw rejectWithValue(error);
        }
    }
);

export const Verify_ForgotPassword = createAsyncThunk(
    'user/verifyForgetPassword',
    async ({ otp, email }, { rejectWithValue }) => {
        try {
            const response = await client.query({
                query: Verify_ForgotPassword_Otp,
                variables: {
                    otp: otp,
                    email: email
                },
            });
            return response.data.VerifyForgotPasswordOtp;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const update_ForgotPassword = createAsyncThunk(
    'user/updateForgotPassword',
    async ({ userId, newPassword }, { rejectWithValue }) => {
        try {
            const response = await client.mutate({
                mutation: Update_Forgot_Password,
                variables: {
                    userId: userId,
                    newPassword: newPassword,
                },
            });
            return response.data.UpdateForgotPassword;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);