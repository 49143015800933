import { createSlice } from "@reduxjs/toolkit";
import { getMessages, sendMessages } from "./actions";

const initialState = {
  chatlist: undefined,
  chatloading: false,
  chaterror: undefined,

  sendmessagelist: undefined,
  sendmessageloading: false,
  sendmessageerror: undefined,
};
const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    newchatlist: (state, action) => {
      state.chatlist = {
        ...state.chatlist,
        data: [action.payload, ...state.chatlist.data],
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getMessages.pending, (state) => {
        state.chatloading = true;
        state.chaterror = null;
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.chatloading = false;
        state.chatlist = action.payload;
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.chatloading = false;
        state.chaterror = action.payload;
      })

      .addCase(sendMessages.pending, (state) => {
        state.sendmessageloading = true;
        state.sendmessageerror = null;
      })
      .addCase(sendMessages.fulfilled, (state, action) => {
        state.sendmessageloading = false;
        state.sendmessagelist = action.payload;
      })
      .addCase(sendMessages.rejected, (state, action) => {
        state.sendmessageloading = false;
        state.sendmessageerror = action.payload;
      });
  },
});
export const { newchatlist } = chatSlice.actions;
export default chatSlice.reducer;
