import { createAsyncThunk } from "@reduxjs/toolkit";
import client from "../../../apollo-client";
import {
  FacebookLogin,
  GoogleLogin,
} from "../../../Mutation/Login/mutation";
import {
  Get_City,
  Get_Counties,
  Get_State,
  Resend_OTP,
  Verify_Account,
} from "../../../Query/Verification/query";
import { SELECT_REGISTER_TYPE, SIGN_UP } from "../../../Mutation/SignUp/mutation";
import {
  Add_Address,
  DELETE_ADDRESS,
  Set_Profile,
  Update_Address,
} from "../../../Mutation/SetProfile/mutation";
import { GET_ADDRESS, GET_ADDRESSES } from "../../../Query/AddressBook/query";
import { LOGOUT } from "../../../Mutation/Logout/mutation";
import { ChangePassword } from "../../../Mutation/ChangePassword/mutataion";
import { Get_Profile } from "../../../Query/GetProfile/query";

export const signUp = createAsyncThunk(
  "auth/signUp",
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.mutate({
        mutation: SIGN_UP,
        variables: {
          email: email,
          password: password,
        },
      });
      return response.data.SignUp;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const registerType = createAsyncThunk(
  "auth/registerType",
  async ({ registration_type, company_vat_number,company_registration_number }, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.mutate({
        mutation: SELECT_REGISTER_TYPE,
        variables: {
          registration_type: registration_type,
          company_vat_number: company_vat_number,
          company_registration_number:company_registration_number
        },
      });
      return response.data.SelectRegistrationType;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyAccount = createAsyncThunk(
  "auth/verifyAccount",
  async ({ userId, otp }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Verify_Account,
        variables: {
          userId: userId,
          otp: otp,
        },
      });
      return response.data.VerifyAccount;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resendOTP = createAsyncThunk(
  "auth/resendOTP",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Resend_OTP,
        variables: {
          userId: userId,
        },
      });
      return response.data.ResendOTP;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setProfile = createAsyncThunk(
  "user/setProfile",
  async ({ name, email, phone_number, company_name }, { rejectWithValue }) => {
    try {
      const response = await client.mutate({
        mutation: Set_Profile,
        variables: {
          name,
          email,
          phone_number,
          company_name,
        },
      });

      return response.data.SetProfile;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const addAddress = createAsyncThunk(
  "user/setAddress",
  async (
    {
      title,
      house_number,
      address,
      postal_code,
      is_default,
      country_id,
      state_id,
      city_id,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.mutate({
        mutation: Add_Address,
        variables: {
          title,
          house_number,
          address,
          postal_code,
          is_default,
          country_id,
          state_id,
          city_id,
        },
      });

      return response.data.AddAddress;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
export const updateAddress = createAsyncThunk(
  "user/updateAddress",
  async (
    {
      id,
      title,
      house_number,
      address,
      postal_code,
      is_default,
      country_id,
      state_id,
      city_id,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.mutate({
        mutation: Update_Address,
        variables: {
          id,
          title,
          house_number,
          address,
          postal_code,
          is_default,
          country_id,
          state_id,
          city_id,
        },
      });

      return response.data.UpdateAddress;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getAddresses = createAsyncThunk(
  "user/getAddresses",
  async ({ first, page, str }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_ADDRESS,
        variables: { first, page, str },
      });
      return response.data.GetAddresses.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getSpecificAddresses = createAsyncThunk(
  "user/getSpecificAddresses",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_ADDRESSES,
        variables: { id },
      });
      return response.data.GetAddress;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const deleteAddress = createAsyncThunk(
  "user/deleteAddress",
  async (id, { rejectWithValue }) => {
    try {
      const response = await client.mutate({
        mutation: DELETE_ADDRESS,
        variables: { id },
      });
      return response.data.DeleteAddress.meta;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const GetCounties = createAsyncThunk(
  "states/GetCounties",
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Get_Counties,
      });
      return response.data.GetCounties;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const GetStates = createAsyncThunk(
  "states/GetStates",
  async ({ country_id }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Get_State,
        variables: {
          country_id: country_id,
        },
      });
      return response.data.GetStates;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const GetCity = createAsyncThunk(
  "states/GetCity",
  async ({ state_id }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Get_City,
        variables: {
          state_id: state_id,
        },
      });
      return response.data.GetCities;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logout",
  async ({ deviceId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.mutate({
        mutation: LOGOUT,
        variables: { deviceId },
      });
      return response.data.Logout;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (
    { old_password, confirm_password, new_password },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.mutate({
        mutation: ChangePassword,
        variables: {
          old_password,
          confirm_password,
          new_password,
        },
      });

      return response.data.ChangePassword;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const fetchUserProfile = createAsyncThunk(
  "user/fetchUserProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: Get_Profile,
      });
      return response.data.user;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const googleLogin = createAsyncThunk(
  "auth/googleLogin",
  async ({ token }, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.mutate({
        mutation: GoogleLogin,
        variables: {
          token: token,
        },
      });
      return response.data.GoogleLogin;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const facebookLogin = createAsyncThunk(
  "auth/facebookLogin",
  async ({ token }, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.mutate({
        mutation: FacebookLogin,
        variables: {
          token: token,
        },
      });
      return response.data.FacebookLogin;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
