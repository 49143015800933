import { gql } from "@apollo/client";
export const All_SupportTickets = gql`
  query AllSupportTickets {
    AllSupportTickets {
      serial_no
    }
  }
`;

export const Get_SupportTickets = gql`
  query GetSupportTickets(
    $first: Int!
    $page: Int!
    $type: String!
    $status: String
  ) {
    GetSupportTickets(
      first: $first
      page: $page
      type: $type
      status: $status
    ) {
      data {
        id
        user {
          id
          name
        }
        subject
        message
        status
        product_name
        serial_no
        created_at
        updated_at
        productRegistration {
          id
        }
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`;

export const Get_SupportTicketById = gql`
  query GetSupportTicketById($id: ID!) {
    GetSupportTicketById(id: $id) {
      id
      user {
        id
        name
      }
      req_serial_no
      subject
      message
      status
      product_name
      serial_no
      created_at
      updated_at
      productRegistration {
        id
      }
    }
  }
`;
