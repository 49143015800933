import { gql } from "@apollo/client";
export const Get_Messages = gql`
  query GetMessages($first: Int!, $page: Int!) {
    GetMessages(first: $first, page: $page) {
      data {
        message
        created_at
        from {
          id
          name
        }
        to {
          id
          name
        }
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
    user {
      id
      chat_support_req
    }
  }
`;
