import { createSlice } from "@reduxjs/toolkit";
import { registerProduct } from "./actions";
import { notification } from "antd";

const initialState = {
  productregisterlist: null,
  productregisterloading: false,
  productregistererror: null,
};
const productRegisterSlice = createSlice({
  name: "productRegister",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerProduct.pending, (state) => {
        state.productregisterloading = true;
        state.productregistererror = null;
      })
      .addCase(registerProduct.fulfilled, (state, action) => {
        state.productregisterloading = false;
        state.productregisterlist = action.payload;
        notification.success({
          message: "Success",
          description: action?.payload?.meta?.message,
          duration: 2,
        });
      })
      .addCase(registerProduct.rejected, (state, action) => {
        state.productregisterloading = false;
        state.productregistererror = action;
        notification.error({
          message: "Error",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      });
  },
});
export default productRegisterSlice.reducer;
