import { gql } from "@apollo/client";
export const LOGOUT = gql`
  mutation Logout($deviceId: String) {
    Logout(deviceId: $deviceId) {
      meta {
        status
        message
        code
      }
    }
  }
`;
