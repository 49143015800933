import { gql } from "@apollo/client";
export const GET_CATEGORIES = gql`
  query GetCategories($first: Int!, $page: Int!) {
    GetCategories(first: $first, page: $page) {
        data{
            id
           	name
            totalProducts
        }
        paginatorInfo {
            currentPage
			total
        }
    }
}`;