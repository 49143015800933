import { GET_Testimonial } from "../../../Query/HomePage/query";
import { createAsyncThunk } from '@reduxjs/toolkit';
import client from "../../../apollo-client";
export const fetchTestimonial = createAsyncThunk(
  "testimonial/getTestimonial",
  async ({ first, page }, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_Testimonial,
        variables: { first, page },
      });
      return response.data.GetTestimonials.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);