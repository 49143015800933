// slice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  GetCity,
  GetCounties,
  GetStates,
  addAddress,
  changePassword,
  deleteAddress,
  fetchUserProfile,
  getAddresses,
  getSpecificAddresses,
  googleLogin,
  logoutUser,
  registerType,
  resendOTP,
  setProfile,
  signUp,
  updateAddress,
  verifyAccount,
} from "./actions";
import { notification } from "antd";

const initialState = {
  list: [],
  selectedLocation: null,
  loading: false,
  error: null,

  signUpList: undefined,
  signUpLoading: false,
  signUperror: null,

  verifyAccountList: [],
  verifyAccountLoading: false,
  verifyAccounterror: null,

  registrationTypeList:undefined,
  registerTypeError:null,
  registerTypeLoading:false,

  getCountryList: [],
  getCoutryError: null,
  getCountryLoading: false,

  getStateList: [],
  getStateError: null,
  getStateLoading: false,

  getCityList: [],
  getCityError: null,
  getCityLoading: false,

  setProfileList: [],
  setProfileError: null,
  setProfileLoading: false,

  setAddressList: [],
  setAddressError: null,
  setAddressLoading: false,

  updateAddressList: [],
  updateAddressError: null,
  updateAddressLoading: false,

  getAddressList: [],
  getAddressError: null,
  getAddressLoading: false,

  getSpecificAddressList: null,
  getSpecificAddressError: null,
  getSpecificAddressLoading: false,

  DeleteAddressList: [],
  DeleteAddressError: null,
  DeleteAddressLoading: false,

  resendOTPList: [],
  resendOTPError: null,
  resendOTPLoading: false,

  logoutList: [],
  logoutError: null,
  logoutLoading: false,

  changePasswordList: [],
  changePasswordError: null,
  changePasswordLoading: false,

  getProfileList: undefined,
  getProfileError: null,
  getProfileLoading: false,

  googleLoginList: [],
  googleLoginError: null,
  googleLoginLoading: false,

};
const networkLocationSlice = createSlice({
  name: 'networkLocations',
  initialState,
  reducers: {
    updateWishListCount: (state, action) => {
      state.getProfileList = {
        ...state.getProfileList,
        wishlist_count: action.payload.wishlistCount
      };
    },

    updateChatCount: (state, action) => {
      state.getProfileList = {
        ...state.getProfileList,
        unread_messages: action.payload.unreadChatCount
      };
    },
    updateNotificationListCount: (state, action) => {
      state.getProfileList = {
        ...state.getProfileList,
        unread_notifications: action.payload.unread_notifications
      };
    },

    newAddressList: (state, action) => {
      state.getAddressList = [...state.getAddressList, action.payload]
    },
    newUpdatedAddressList: (state, action) => {
      state.updateAddressList = []
    },
    updateSetAddressList: (state, action) => {
      state.setAddressList = []
    },
    clearAddressList: (state, action) => {
      state.getSpecificAddressList = null
    },

    // Delete Specific Address Reducer
    deleteAddressById: (state, action) => {
      const idToDelete = action.payload;
      state.getAddressList = state.getAddressList.filter(address => address.id !== idToDelete);
    },

    // After Delete Update Address List
    updateDeleteAddressList: (state, action) => {
      state.DeleteAddressList = []
    },

    updateNewAddressInList: (state, action) => {
      const updatedAddress = action.payload;
      state.getAddressList = state.getAddressList.map(address => {
        if (address.id === updatedAddress.id) {
          return updatedAddress;
        }
        return {
          ...address,
          is_default: false
        };
      });
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending, (state) => {
        state.signUpLoading = true;
        state.signUperror = null;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.signUpLoading = false;
        state.signUpList = action.payload;
        notification.success({
          message: "Success",
          description: action?.payload?.meta?.message,
          duration: 2,
        });
      })
      .addCase(signUp.rejected, (state, action) => {
        state.signUpLoading = false;
        state.signUperror = action;
        notification.error({
          message: "Error",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })
      .addCase(verifyAccount.pending, (state) => {
        state.verifyAccountLoading = true;
        state.verifyAccounterror = null;
      })
      .addCase(verifyAccount.fulfilled, (state, action) => {
        state.verifyAccountLoading = false;
        state.verifyAccountList = action.payload;
        notification.success({
          message: "Email Verified Successfully",
          description: action?.payload?.meta?.message,
          duration: 2,
        });
      })
      .addCase(verifyAccount.rejected, (state, action) => {
        state.verifyAccountLoading = false;
        state.verifyAccounterror = action.payload;
        notification.error({
          message: "Email Verification Failed",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })
      .addCase(registerType.pending, (state) => {
        state.registerTypeLoading = true;
        state.registerTypeError = null;
      })
      
      .addCase(registerType.fulfilled, (state, action) => {
        state.registerTypeLoading = false;
        state.registrationTypeList = action.payload;
        // notification.success({
        //   message: "Success",
        //   description: action?.payload?.meta?.message,
        //   duration: 2,
        // });
      })
      .addCase(registerType.rejected, (state, action) => {
        state.registerTypeLoading = false;
        state.registerTypeError = action;
        // notification.error({
        //   message: "Error",
        //   description: action?.payload?.graphQLErrors[0]?.message,
        //   duration: 2,
        // });
      })
      .addCase(resendOTP.pending, (state) => {
        state.resendOTPLoading = true;
        state.resendOTPError = null;
      })
      .addCase(resendOTP.fulfilled, (state, action) => {
        state.resendOTPLoading = false;
        state.resendOTPList = action.payload;
        notification.success({
          message: "Operation Successful",
          description: action?.payload?.meta?.message,
          duration: 2,
        });
      })
      .addCase(resendOTP.rejected, (state, action) => {
        state.resendOTPLoading = false;
        state.resendOTPError = action.payload;
        notification.error({
          message: "Operation Failed",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })
      .addCase(setProfile.pending, (state) => {
        state.setProfileLoading = true;
        state.setProfileError = null;
      })
      .addCase(setProfile.fulfilled, (state, action) => {
        state.setProfileLoading = false;
        state.setProfileList = action.payload;
        notification.success({
          message: "Profile Successfully Set",
          description: action?.payload?.meta?.message,
          duration: 2,
        });
      })
      .addCase(setProfile.rejected, (state, action) => {
        state.setProfileLoading = false;
        state.setProfileError = action.payload;
        notification.error({
          message: "Error Setting Profile",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })
      .addCase(addAddress.pending, (state) => {
        state.setAddressLoading = true;
        state.setAddressError = null;
      })
      .addCase(addAddress.fulfilled, (state, action) => {
        state.setAddressLoading = false;
        state.setAddressList = action.payload;
        notification.success({
          message: "Address Successfully Set",
          description: action?.payload?.meta?.message,
          duration: 2,
        });
      })
      .addCase(addAddress.rejected, (state, action) => {
        state.setAddressLoading = false;
        state.setAddressError = action.payload;
        notification.error({
          message: "Operation Failed",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })
      .addCase(updateAddress.pending, (state) => {
        state.updateAddressLoading = true;
        state.updateAddressError = null;
      })
      .addCase(updateAddress.fulfilled, (state, action) => {
        state.updateAddressLoading = false;
        state.updateAddressList = action.payload;
        notification.success({
          message: "Operation Success",
          description: action?.payload?.meta?.message,
          duration: 2,
        });
      })
      .addCase(updateAddress.rejected, (state, action) => {
        state.updateAddressLoading = false;
        state.updateAddressError = action.payload;
        notification.error({
          message: "Operation Failed",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })
      .addCase(getAddresses.pending, (state) => {
        state.getAddressLoading = true;
        state.getAddressError = null;
      })
      .addCase(getAddresses.fulfilled, (state, action) => {
        state.getAddressLoading = false;
        state.getAddressList = action.payload;
      })
      .addCase(getAddresses.rejected, (state, action) => {
        state.getAddressLoading = false;
        state.getAddressError = action.payload;
      })
      .addCase(getSpecificAddresses.pending, (state) => {
        state.getSpecificAddressLoading = true;
        state.getSpecificAddressError = null;
      })
      .addCase(getSpecificAddresses.fulfilled, (state, action) => {
        state.getSpecificAddressLoading = false;
        state.getSpecificAddressList = action.payload;
      })
      .addCase(getSpecificAddresses.rejected, (state, action) => {
        state.getSpecificAddressLoading = false;
        state.getSpecificAddressError = action.payload;
      })
      .addCase(deleteAddress.pending, (state) => {
        state.DeleteAddressLoading = true;
        state.DeleteAddressError = null;
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        state.DeleteAddressLoading = false;
        state.DeleteAddressList = action.payload;
        notification.success({
          message: "Address Deleted!",
          description: action?.payload?.message,
          duration: 2,
        });
      })
      .addCase(deleteAddress.rejected, (state, action) => {
        state.DeleteAddressLoading = false;
        state.DeleteAddressError = action.payload;
        notification.error({
          message: "Error",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })
      .addCase(GetCounties.pending, (state) => {
        state.getCountryLoading = true;
        state.getCoutryError = null;
      })
      .addCase(GetCounties.fulfilled, (state, action) => {
        state.getCountryLoading = false;
        state.getCountryList = action.payload;
      })
      .addCase(GetCounties.rejected, (state, action) => {
        state.getCountryLoading = false;
        state.getCoutryError = action.payload;
      })
      .addCase(GetStates.pending, (state) => {
        state.getStateLoading = true;
        state.getStateError = null;
      })
      .addCase(GetStates.fulfilled, (state, action) => {
        state.getStateLoading = false;
        state.getStateList = action.payload;
      })
      .addCase(GetStates.rejected, (state, action) => {
        state.getStateLoading = false;
        state.getStateError = action.payload;
      })
      .addCase(GetCity.pending, (state) => {
        state.getCityLoading = true;
        state.getCityError = null;
      })
      .addCase(GetCity.fulfilled, (state, action) => {
        state.getCityLoading = false;
        state.getCityList = action.payload;
      })
      .addCase(GetCity.rejected, (state, action) => {
        state.getCityLoading = false;
        state.getCityError = action.payload;
      })

      // Logout
      .addCase(logoutUser.pending, (state) => {
        state.logoutLoading = true;
        state.logoutError = null;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.logoutLoading = false;
        state.logoutList = action.payload;
        notification.success({
          message: "Logout Successfully",
          description: "Logout Successfully",
          duration: 2
        });
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.logoutLoading = false;
        state.logoutError = action.payload;

        notification.error({
          message: "Operation Failed",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2
        });
      })

      // Forget Password
      .addCase(changePassword.pending, (state) => {
        state.changePasswordLoading = true;
        state.changePasswordError = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.changePasswordLoading = false;
        state.changePasswordList = action.payload;
        notification.success({
          message: "Password Successfully Set",
          description: action?.payload?.meta?.message,
          duration: 2
        });
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.changePasswordLoading = false;
        state.changePasswordError = action.payload;
        notification.error({
          message: "Error Change Password",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })

      // Get Profile
      .addCase(fetchUserProfile.pending, (state) => {
        state.getProfileLoading = true;
        state.getProfileError = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.getProfileLoading = false;
        state.getProfileList = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.getProfileLoading = false;
        state.getProfileError = action.payload;
      })

      // Google Login
      .addCase(googleLogin.fulfilled, (state, action) => {
        state.googleLoginLoading = false;
        state.googleLoginList = action.payload;
        notification.success({
          message: "Success",
          description: "Login Successfully completed",
          duration: 2,
        });
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.googleLoginLoading = false;
        state.googleLoginError = action.payload;
        notification.error({
          message: "Error",
          description: action?.payload?.graphQLErrors[0]?.message,
          duration: 2,
        });
      })
      .addCase(googleLogin.pending, (state) => {
        state.googleLoginLoading = true;
        state.googleLoginError = null;
      });
  },
});
export const {
  newAddressList,
  newUpdatedAddressList,
  updateIsDefault,
  updateNewAddressInList,
  updateSetAddressList,
  updateGetSpecificAddressList,
  clearAddressList,
  deleteAddressById,
  updateNewAddressList,
  updateDeleteAddressList,
  updateWishListCount,
  updateNotificationListCount,
  updateChatCount,
} = networkLocationSlice.actions;
export default networkLocationSlice.reducer;
