import { createSlice } from "@reduxjs/toolkit";
import { fetchTestimonial } from "./actions";

const initialState = {
    testimonialloading: false,
    testimonialerror: null,
    testimoniallist: [],
}
const testimonialSlice = createSlice({
    name: 'testimonial',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTestimonial.pending, (state) => {
                state.testimonialloading = true;
                state.testimonialerror = null;
            })
            .addCase(fetchTestimonial.fulfilled, (state, action) => {
                state.testimonialloading = false;
                state.testimoniallist = action.payload;
            })
            .addCase(fetchTestimonial.rejected, (state, action) => {
                state.testimonialloading = false;
                state.testimonialerror = action.payload;
            })
    },
});
export default testimonialSlice.reducer;